import { createRouter, createWebHistory } from 'vue-router';
import NewProduct from '../views/products/NewProduct.vue';
import Dashboard from '../views/dashboard/Dashboard.vue';
import Leaderboard from '@/views/leaderboard/Leaderboard.vue';
import ProductsList from '../views/products/ProductsList.vue';
import UsersList from '@/views/users/UsersList.vue';
import NewUser from '../views/users/NewUser.vue';
import Settings from '../views/profile/Settings.vue';
import Login from '../views/auth/signin/Login.vue';
import Signup from '../views/auth/signup/Signup.vue';
import Error404 from '../views/auth/error/Error404.vue';
import Error500 from '../views/auth/error/Error500.vue';
import ViewUser from '@/views/users/ViewUser.vue';
import OrdersList from '@/views/orders/OrdersList.vue';
import Blacklist from '@/views/blacklist/Blacklist.vue';
import Reservations from '@/views/reservations/ReservationsList.vue';
import NewOrder from '@/views/orders/NewOrder.vue';
import Reports from '@/views/reports/Reports.vue';
import EditProduct from '@/views/products/ViewProduct.vue';
import ViewOrder from '@/views/orders/ViewOrder.vue';
import { actions, store } from '@/store';
import Forgot from '@/views/auth/forgot/Forgot.vue';
import Reset from '@/views/auth/reset/Reset.vue';
import BonusPayments from '@/views/bonus/BonusPayments.vue';

const routes = [
  {
    path: '/',
    name: '/',
    redirect: '/auth/login',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      protected: true,
      roles: ['Marketer', 'Manager', 'Admin'],
    },
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    component: Leaderboard,
    meta: {
      protected: true,
      roles: ['Marketer', 'Admin', 'Manager'],
    },
  },
  {
    path: '/profile',
    name: 'Profile Overview',
    component: Settings,
    meta: {
      protected: true,
      roles: ['Marketer', 'Manager', 'Admin'],
    },
  },
  {
    path: '/products',
    name: 'Active Products',
    component: ProductsList,
    meta: {
      protected: true,
      roles: ['Marketer', 'Manager', 'Admin'],
    },
  },
  {
    path: '/products/archived',
    name: 'Archived Products',
    component: ProductsList,
    meta: {
      protected: true,
      roles: ['Manager', 'Admin'],
    },
  },
  {
    path: '/products/add',
    name: 'Add Product',
    component: NewProduct,
    meta: {
      protected: true,
      roles: ['Manager', 'Admin'],
    },
  },
  {
    path: '/products/:productId',
    name: 'View Product',
    component: EditProduct,
    meta: {
      protected: true,
      roles: ['Marketer', 'Manager', 'Admin'],
    },
  },
  {
    path: '/orders',
    name: 'Orders List',
    component: OrdersList,
    meta: {
      protected: true,
      roles: ['Marketer', 'Manager', 'Admin'],
    },
  },
  {
    path: '/orders/add/:reservationId',
    name: 'Submit Order',
    component: NewOrder,
    meta: {
      protected: true,
      roles: ['Marketer'],
    },
  },
  {
    path: '/orders/:orderId',
    name: 'View Order',
    component: ViewOrder,
    meta: {
      protected: true,
      roles: ['Marketer', 'Manager', 'Admin'],
    },
  },
  {
    path: '/reservations',
    name: 'Reservations List',
    component: Reservations,
    meta: {
      protected: true,
      roles: ['Marketer', 'Manager', 'Admin'],
    },
  },
  {
    path: '/users/add',
    name: 'Add User',
    component: NewUser,
    meta: {
      protected: true,
      roles: ['Admin'],
    },
  },
  {
    path: '/users',
    name: 'Active Users',
    component: UsersList,
    meta: {
      protected: true,
      roles: ['Admin'],
    },
  },
  {
    path: '/users/inactive',
    name: 'InActive Users',
    component: UsersList,
    meta: {
      protected: true,
      roles: ['Admin'],
    },
  },
  {
    path: '/users/terminated',
    name: 'Terminated Users',
    component: UsersList,
    meta: {
      protected: true,
      roles: ['Admin'],
    },
  },
  {
    path: '/users/restricted',
    name: 'Restricted Users',
    component: UsersList,
    meta: {
      protected: true,
      roles: ['Admin'],
    },
  },
  {
    path: '/users/pending',
    name: 'Pending Users',
    component: UsersList,
    meta: {
      protected: true,
      roles: ['Admin'],
    },
  },
  {
    path: '/users/:userId',
    name: 'View User',
    component: ViewUser,
    meta: {
      protected: true,
      roles: ['Admin', 'Manager', 'Marketer'],
    },
  },
  {
    path: '/bonus-payments',
    name: 'Bonus Payments',
    component: BonusPayments,
    meta: {
      protected: true,
      roles: ['Admin'],
    },
  },
  {
    path: '/reports',
    name: 'Reports',
    component: Reports,
    meta: {
      protected: true,
      roles: ['Marketer', 'Manager', 'Admin'],
    },
  },
  {
    path: '/blacklist',
    name: 'Blacklist',
    component: Blacklist,
    meta: {
      protected: true,
      roles: ['Marketer', 'Manager', 'Admin'],
    },
  },
  {
    path: '/auth/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/auth/forgot-password',
    name: 'Forgot',
    component: Forgot,
  },
  {
    path: '/auth/reset-password/:token',
    name: 'Reset',
    component: Reset,
  },
  {
    path: '/auth/signup',
    name: 'Signup',
    component: Signup,
  },
  {
    path: '/500',
    name: 'Error Error500',
    component: Error500,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error Error404',
    component: Error404,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
});

// Check Auth For Protected Routes
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta?.protected);
  if (requiresAuth) {
    actions
      .api('get', '/auth')
      .then((res) => {
        if (res.user) {
          if (!to.meta.roles.includes(res.user.role)) {
            next('/dashboard');
          } else {
            store.user = res.user;
            store.role = res.user.role;
            next();
          }
        } else {
          next('/auth/login');
        }
      })
      .catch(() => {
        next('/auth/login');
      });
  } else {
    next();
  }
});

export default router;
