<template>
  <main class="main-content main-content-bg mt-0">
    <div
      class="page-header min-vh-100"
      style="
        background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signin-basic.jpg');
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-xxl-4 col-md-7 mt-2">
            <div class="card border-0 mb-0">
              <div class="card-header bg-transparent">
                <h5 class="text-dark text-center mt-2 mb-3">Sign in</h5>
              </div>
              <div class="card-body px-lg-5 pt-0">
                <form role="form" class="text-start" @submit.prevent="handleLogin">
                  <div class="mb-3">
                    <argon-input
                      id="email"
                      v-model="user.email"
                      type="email"
                      placeholder="Email *"
                      aria-label="Email"
                      is-required
                    />
                  </div>
                  <div class="mb-3">
                    <argon-input
                      id="password"
                      v-model="user.password"
                      type="password"
                      placeholder="Password *"
                      aria-label="Password"
                      is-required
                    />
                  </div>

                  <div class="row">
                    <div class="col-6">
                      <argon-switch id="rememberMe" name="rememberMe" v-model="user.remember">
                        Remember
                      </argon-switch>
                    </div>
                    <div class="col-6 text-end">
                      <router-link to="/auth/forgot-password" class="m-0 text-sm">
                        Forgot password?
                      </router-link>
                    </div>
                  </div>

                  <div class="text-center">
                    <argon-button
                      color="primary"
                      variant="gradient"
                      full-width
                      class="my-4 mb-2"
                      type="submit"
                      :is-disabled="loading"
                    >
                      <i v-if="loading" class="fa fa-refresh fa-spin" />
                      <span v-else>Sign In</span>
                    </argon-button>
                  </div>
                  <div class="mb-2 position-relative text-center">
                    <p
                      class="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3"
                    >
                      or
                    </p>
                  </div>
                  <div class="text-center">
                    <argon-button
                      color="dark"
                      variant="gradient"
                      full-width
                      class="mt-2 mb-4"
                      @click="$router.push('/auth/signup')"
                    >
                      Sign up
                    </argon-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<script>
// Libs
import { useToast } from 'vue-toastification';
import to from 'await-to-js';

// Components
import AppFooter from '@/components/AuthFooter.vue';
import ArgonInput from '@/components/ArgonInput.vue';
import ArgonSwitch from '@/components/ArgonSwitch.vue';
import ArgonButton from '@/components/ArgonButton.vue';
import router from '@/router';

// Init
const toast = useToast();

export default {
  name: 'LoginView',
  components: {
    AppFooter,
    ArgonInput,
    ArgonSwitch,
    ArgonButton,
  },
  inject: ['store', 'actions'],
  data() {
    return {
      loading: false,
      user: {
        email: '',
        password: '',
        remember: false,
      },
    };
  },
  created() {
    this.store.hideConfigButton = true;
    this.actions.toggleDefaultLayout();
  },
  beforeUnmount() {
    this.store.hideConfigButton = false;
    this.actions.toggleDefaultLayout();
  },
  methods: {
    async handleLogin() {
      const { email, password } = this.user;

      if (!email || !password) {
        return toast.error('Email and Password cannot be empty');
      }

      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        return toast.error('Email is not valid');
      }

      if (password.length < 8) {
        return toast.error('Password length should be at least 8');
      }

      this.loading = true;

      const [, data] = await to(this.actions.api('post', '/auth/login', this.user));

      this.loading = false;

      if (data) {
        this.store.showWelcome = true;
        localStorage.setItem('token', data.token);

        setTimeout(() => {
          router.push('/dashboard');
        }, 3000);
      }
    },
  },
};
</script>
