const marketPlaces = [
  { value: '', label: 'Select AMZ marketplace' },
  { value: 'United States', label: '🇺🇸 United States' },
  { value: 'United Kingdom', label: '🇬🇧 United Kingdom' },
  { value: 'Canada', label: '🇨🇦 Canada' },
  { value: 'Australia', label: '🇦🇺 Australia' },
  { value: 'France', label: '🇫🇷 France' },
  { value: 'Germany', label: '🇩🇪 Germany' },
  { value: 'Italy', label: '🇮🇹 Italy' },
  { value: 'Japan', label: '🇯🇵 Japan' },
  { value: 'Mexico', label: '🇲🇽 Mexico' },
  { value: 'Netherlands', label: '🇳🇱 Netherlands' },
  { value: 'Spain', label: '🇪🇸 Spain' },
  { value: 'United Arab Emirates', label: '🇦🇪 United Arab Emirates' },
  { value: 'Brazil', label: '🇧🇷 Brazil' },
  { value: 'Singapore', label: '🇸🇬 Singapore' },
  { value: 'Turkey', label: '🇹🇷 Turkey' },
  { value: 'India', label: '🇮🇳 India' },
];

export default marketPlaces;
