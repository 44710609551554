<template>
  <div class="py-4 px-1 px-sm-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0 text-center text-sm-start">Reservations</h5>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table id="reservations-list" class="table align-items-center mb-0">
                <thead class="thead-light">
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Picture
                    </th>
                    <th
                      colspan="3"
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Details
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-show="loading && !store.reservations.length">
                    <td colspan="8">
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr v-show="!loading && !store.reservations.length">
                    <td colspan="8">
                      <div class="d-flex justify-content-center">
                        <p class="text-monospace">No data found!</p>
                      </div>
                    </td>
                  </tr>

                  <tr
                    v-for="{
                      _id,
                      resv_id,
                      product,
                      manager,
                      marketer,
                      createdAt,
                    } in store.reservations"
                    :key="_id"
                  >
                    <td class="ps-4 py-3">
                      <img class="order-image" :src="`${mediaUrl}${product.product_image}`" />
                    </td>
                    <td>
                      <p class="text-sm my-1">
                        <strong>Marketplace:</strong>
                        {{ country(product.amz_marketplace) }}
                        <span v-show="product.market_type !== 'Amazon'">
                          {{ product.market_type }}
                        </span>
                      </p>
                      <p class="text-sm my-1" :title="product.keywords">
                        <strong>Keywords:</strong>
                        {{ actions.shortenString(product.keywords) }}
                      </p>
                      <p class="text-sm my-1">
                        <strong>Review Type:</strong>
                        {{ product.review_type }}
                      </p>
                    </td>
                    <td>
                      <p class="text-sm my-1">
                        <strong>RESV#:</strong>
                        {{ resv_id }}
                      </p>
                      <p v-if="store.role === 'Manager'" class="text-sm my-1">
                        <strong>Marketer:&nbsp;</strong>
                        <router-link :to="`/users/${marketer.user_id}`">
                          {{ marketer.first_name }} {{ marketer.last_name }}
                        </router-link>
                        <WALink :phone="marketer.whatsapp_number" />
                      </p>
                      <p v-else class="text-sm my-1">
                        <strong>Manager:&nbsp;</strong>
                        <router-link :to="`/users/${manager.user_id}`">
                          {{ manager.first_name }} {{ manager.last_name }}
                        </router-link>
                        <WALink :phone="manager.whatsapp_number" />
                      </p>
                      <p class="text-sm my-1">
                        <strong>Remaining Sales:</strong>
                        {{ product.remaining_daily_limit }} / {{ product.remaining_total_limit }}
                      </p>
                    </td>
                    <td>
                      <p class="text-sm my-1">
                        <strong>Reservation Time:&nbsp;</strong>
                        <ReservationTime :time="createdAt" />
                      </p>
                      <p class="text-sm my-1">
                        <strong>MPIN#:</strong>
                        {{ product.product_id }}&nbsp;
                        <router-link :to="`/products/${product.product_id}`">
                          (View Details)
                        </router-link>
                      </p>
                      <p v-if="store.role === 'Admin'" class="text-sm my-1">
                        <strong>Marketer:&nbsp;</strong>
                        <router-link :to="`/users/${marketer.user_id}`">
                          {{ marketer.first_name }} {{ marketer.last_name }}
                        </router-link>
                      </p>
                      <p v-else class="text-sm my-1">
                        <strong>Sales Refresh At:</strong>
                        05:00am PKT 🇵🇰
                      </p>
                    </td>

                    <td class="text-sm pe-3 pe-sm-2">
                      <button
                        type="button"
                        class="d-block mb-1 btn btn-outline-primary btn-sm"
                        style="width: 108px"
                        :disabled="releaseLoading[_id]"
                        @click="releaseReservation(_id)"
                      >
                        <i v-if="releaseLoading[_id]" class="fa fa-refresh fa-spin" />
                        <span v-else>Release</span>
                      </button>
                      <router-link
                        v-if="store.role === 'Marketer'"
                        role="button"
                        class="d-block mb-0 btn bg-gradient-primary btn-sm"
                        style="width: 108px"
                        :to="`/orders/add/${resv_id}`"
                      >
                        Order
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--     Footer       -->
            <div class="row p-4">
              <div class="col-md-6">
                <p class="text-sm m-0" style="line-height: 36px">
                  Showing {{ store.reservations.length }} entries
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import country from '../../utils/country';
import to from 'await-to-js';
import ReservationTime from '@/components/ReservationTime.vue';
import WALink from '@/components/WALink.vue';

const toast = useToast();

export default {
  name: 'ReservationsList',
  components: { WALink, ReservationTime },
  inject: ['store', 'actions'],
  mounted() {
    this.fetchData();
  },
  data() {
    return {
      mediaUrl: process.env.VUE_APP_MEDIA_URL,
      loading: false,
      releaseLoading: {},
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const [, data] = await to(this.actions.api('get', `/reservations`));
      this.loading = false;

      if (data?.success) {
        this.store.reservations = data.reservations;
      }
    },
    async releaseReservation(id) {
      this.releaseLoading[id] = true;
      const [err] = await to(this.actions.api('delete', `/reservations/${id}`));
      this.releaseLoading[id] = false;

      if (!err) {
        toast.success('Released successfully');
        this.fetchData();
      }
    },
    country,
  },
};
</script>

<style>
.order-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
</style>
