<template>
  <div class="mb-4 card">
    <div class="pb-0 card-header">
      <h6>{{ title }}</h6>
    </div>
    <div class="px-0 pt-0 pb-2 card-body">
      <div class="p-0 table-responsive">
        <table class="table mb-0 align-items-center">
          <thead>
            <tr>
              <th
                v-for="(header, index) of headers"
                :key="index"
                :class="index >= 1 ? 'text-center ps-2' : ''"
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-show="!lists.length">
              <td colspan="5">
                <div class="d-flex justify-content-center">
                  <h6 class="text-center py-5 font-weight-bold">No data to show!</h6>
                </div>
              </td>
            </tr>

            <tr v-for="(order, index) in lists" :key="index">
              <td>
                <div class="px-3 py-1 d-flex">
                  <argon-avatar
                    :img="`${mediaUrl}${order.product?.product_image}`"
                    class="me-3"
                    border-radius="lg"
                    alt="Product Image"
                  />
                  <div class="d-flex align-items-center">
                    <h6 class="text-sm my-0">Order ID:</h6>
                    <p class="text-sm my-0 px-2">#{{ order.order_id }}</p>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-center mb-0 text-sm font-weight-bold">
                  {{ order.amz_order_number }}
                </p>
              </td>
              <td>
                <p class="text-center mb-0 text-sm font-weight-bold">
                  {{ country(order.product.amz_marketplace, { short: true }) }}
                </p>
              </td>
              <td class="text-sm align-middle">
                <p class="text-center mb-0 text-sm font-weight-bold">
                  {{ new Date(order.updatedAt).toLocaleString() }}
                </p>
              </td>
              <td class="align-middle text-center">
                <div class="text-center px-3 py-1 d-flex justify-content-center align-items-center">
                  <order-badge :value="order.status" sm />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonAvatar from '@/components/ArgonAvatar.vue';
import country from '../../../utils/country';
import OrderBadge from '@/components/OrderBadge.vue';
export default {
  name: 'ActivityCard',
  components: {
    OrderBadge,
    ArgonAvatar,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    lists: {
      type: Array,
      required: true,
      values: Array,
      title: String,
      amz_order: String,
      img: String,
    },
  },
  data() {
    return {
      mediaUrl: process.env.VUE_APP_MEDIA_URL,
    };
  },
  methods: { country },
};
</script>
