<template>
  <main class="main-content main-content-bg mt-0">
    <div
      class="page-header min-vh-100"
      style="
        background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/signup-basic.jpg');
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container my-4">
        <div class="row justify-content-center">
          <div class="col-lg-7 col-md-10 col-xxl-5">
            <div class="card z-index-0">
              <div class="card-header text-center pt-4 pb-1">
                <h5>Register</h5>
              </div>
              <div class="card-body pt-1">
                <form role="form" @submit.prevent="handleSignup">
                  <!--        Basic Info          -->
                  <div class="row">
                    <div class="col-md-6 p-1">
                      <argon-input
                        v-model="user.first_name"
                        type="text"
                        placeholder="First Name *"
                        aria-label="First Name"
                        class="mt-0 mb-1"
                        is-required
                      />
                    </div>
                    <div class="col-md-6 p-1">
                      <argon-input
                        v-model="user.last_name"
                        type="text"
                        placeholder="Last Name *"
                        aria-label="Last Name"
                        class="mt-0 mb-1"
                        is-required
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 p-1">
                      <argon-input
                        v-model="user.email"
                        type="email"
                        placeholder="Email *"
                        aria-label="Email"
                        class="mt-0 mb-1"
                        is-required
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 p-1">
                      <argon-input
                        v-model="user.cnic_number"
                        type="text"
                        placeholder="CNIC Number *"
                        aria-label="CNIC Number"
                        class="mt-0 mb-1"
                        is-required
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 p-1">
                      <argon-input
                        v-model="user.password"
                        type="password"
                        placeholder="Password *"
                        aria-label="Password"
                        class="mt-0 mb-1"
                        is-required
                      />
                    </div>
                    <div class="col-md-6 p-1">
                      <argon-input
                        v-model="user.confirmPassword"
                        type="password"
                        placeholder="Confirm New Password *"
                        aria-label="Confirm Password"
                        class="mt-0 mb-1"
                        is-required
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6 p-1">
                      <argon-select
                        id="signup-gender"
                        v-model="user.gender"
                        name="gender"
                        :options="[
                          {
                            value: 'Male',
                            label: 'Male',
                          },
                          {
                            value: 'Female',
                            label: 'Female',
                          },
                        ]"
                      />
                    </div>
                    <div class="col-md-6 p-1">
                      <argon-select
                        id="signup-role"
                        v-model="user.role"
                        name="role"
                        :options="[
                          {
                            value: 'Marketer',
                            label: 'Marketer (PM)',
                          },
                          {
                            value: 'Manager',
                            label: 'Manager (PMM)',
                          },
                        ]"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 p-1" :class="user.role === 'Manager' ? 'col-md-6' : ''">
                      <argon-input
                        v-model="user.whatsapp_number"
                        type="text"
                        placeholder="Whatsapp Number *"
                        aria-label="Whatsapp Number"
                        class="mt-0 mb-1"
                        is-required
                      />
                    </div>
                    <div v-show="user.role === 'Manager'" class="col-md-6 p-1">
                      <argon-input
                        v-model="user.va_number"
                        type="text"
                        placeholder="VA Number"
                        aria-label="VA Number"
                        class="mt-0 mb-1"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 p-1">
                      <argon-input
                        v-model="user.address.street"
                        type="text"
                        placeholder="Street Address"
                        aria-label="Street Address"
                        class="mt-0 mb-1"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 p-1">
                      <argon-input
                        v-model="user.address.city"
                        type="text"
                        placeholder="City"
                        aria-label="City"
                        class="mt-0 mb-1"
                      />
                    </div>
                    <div class="col-md-6 p-1">
                      <argon-select
                        id="signup-country"
                        v-model="user.address.country"
                        name="country"
                        :options="countryDropdown"
                      />
                    </div>
                  </div>

                  <!--        Referral Info          -->
                  <div class="row">
                    <div class="col-12 p-1">
                      <argon-input
                        v-model="user.referral.name"
                        type="text"
                        placeholder="Referral Name *"
                        aria-label="Referral Name"
                        class="mt-0 mb-1"
                        is-required
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 p-1">
                      <argon-input
                        v-model="user.referral.email"
                        type="text"
                        placeholder="Referral Email"
                        aria-label="Referral Email"
                        class="mt-0 mb-1"
                      />
                    </div>
                    <div class="col-md-6 p-1">
                      <argon-input
                        v-model="user.referral.contact"
                        type="text"
                        placeholder="Referral Contact"
                        aria-label="Referral Contact"
                        class="mt-0 mb-1"
                      />
                    </div>
                  </div>

                  <!--        Bank Info          -->
                  <div class="row">
                    <div class="col-12 p-1">
                      <argon-select
                        id="signup-bank"
                        v-model="user.bank.name"
                        name="bank"
                        :options="banks"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 p-1">
                      <argon-input
                        v-model="user.bank.title"
                        type="text"
                        placeholder="Account Title"
                        aria-label="Account Title"
                        class="mt-0 mb-1"
                      />
                    </div>
                    <div class="col-md-6 p-1">
                      <argon-input
                        v-model="user.bank.account"
                        type="text"
                        placeholder="Account Number"
                        aria-label="Account Number"
                        class="mt-0 mb-1"
                      />
                    </div>
                  </div>

                  <div class="text-center">
                    <argon-button
                      type="submit"
                      full-width
                      color="dark"
                      variant="gradient"
                      class="my-4 mb-2"
                      :is-disabled="loading"
                    >
                      <i v-if="loading" class="fa fa-refresh fa-spin" />
                      <span v-else>Sign Up</span>
                    </argon-button>
                  </div>
                  <p class="text-sm mt-3 mb-0">
                    Already have an account?
                    <router-link to="/auth/login" class="text-dark font-weight-bolder">
                      Sign in
                    </router-link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<script>
// Libs
import { useToast } from 'vue-toastification';

// Components
import AppFooter from '@/components/AuthFooter.vue';
import ArgonInput from '@/components/ArgonInput.vue';
import ArgonButton from '@/components/ArgonButton.vue';
import ArgonSelect from '@/components/ArgonSelect.vue';

// Constants
import { countryDropdown } from '@/utils/country';
import banks from '@/utils/banks';
import to from 'await-to-js';

// Init
const toast = useToast();
import removeEmptyFields from '@/utils/removeEmptyFields';

export default {
  name: 'SignupView',
  components: {
    ArgonSelect,
    AppFooter,
    ArgonInput,
    ArgonButton,
  },
  inject: ['store', 'actions'],
  data() {
    return {
      loading: false,
      countryDropdown,
      banks,
      user: {
        first_name: '',
        last_name: '',
        email: '',
        cnic_number: '',
        password: '',
        confirmPassword: '',
        whatsapp_number: '',
        va_number: '',
        gender: 'Male',
        role: 'Marketer',
        address: {
          street: '',
          city: '',
          country: '',
        },
        referral: {
          name: '',
          email: '',
          contact: '',
        },
        bank: {
          name: '',
          title: '',
          account: '',
        },
      },
    };
  },
  created() {
    this.store.hideConfigButton = true;
    this.actions.toggleDefaultLayout();
  },
  beforeUnmount() {
    this.store.hideConfigButton = false;
    this.actions.toggleDefaultLayout();
  },
  methods: {
    async handleSignup() {
      const user = removeEmptyFields({ ...this.user });

      const validated = [
        'first_name',
        'last_name',
        'email',
        'cnic_number',
        'whatsapp_number',
        'password',
        'confirmPassword',
        'role',
        'gender',
      ].every((val) => user[val]);

      if (!validated) {
        return toast.error('Fields with * are required');
      }

      if (!user.referral?.name) {
        return toast.error('Referral name must be added');
      }

      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user.email)) {
        return toast.error('Email is not valid');
      }

      if (user.password.length < 8) {
        return toast.error('Password length should be at least 8');
      }

      if (user.password !== user.confirmPassword) {
        return toast.error('Password and Confirm Password are not same');
      }

      user.cnic_number = user.cnic_number?.replaceAll('-', '');

      if (user.cnic_number?.length !== 13) {
        return toast.error('CNIC number is not correct');
      }

      this.loading = true;

      const [, data] = await to(this.actions.api('post', '/auth/signup', user));

      this.loading = false;

      if (data) {
        toast.success('You account is sent for approval');
        this.user = {
          first_name: '',
          last_name: '',
          email: '',
          cnic_number: '',
          password: '',
          confirmPassword: '',
          whatsapp_number: '',
          va_number: '',
          gender: 'Male',
          role: 'Marketer',
          address: {
            street: '',
            city: '',
            country: '',
          },
          referral: {
            name: '',
            email: '',
            contact: '',
          },
          bank: {
            name: '',
            title: '',
            account: '',
          },
        };
      }
    },
  },
};
</script>
