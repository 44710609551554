/**
 * Covert a params object into a query string
 */
export function parseQueryString(obj, parentKey = null) {
  return Object.keys(obj)
    .map((key) => {
      const value = obj[key];

      if (typeof value === 'object') {
        return parseQueryString(value, parentKey ? `${parentKey}[${key}]` : key);
      } else {
        return parentKey ? `${parentKey}[${key}]=${value}` : `${key}=${value}`;
      }
    })
    .join('&');
}
