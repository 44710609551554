<template>
  <form @submit.prevent="handleSearch">
    <div class="row mt-3">
      <div class="col-12 col-md-2 my-1">
        <argon-select
          id="search-product-review-type"
          v-model="$parent.searchParams.review_type"
          :options="[{ value: '', label: 'Review Type' }, ...reviewTypes]"
          :first-disabled="false"
          :use-choices="false"
        />
      </div>
      <div class="col-12 col-md-3 my-1">
        <argon-select
          id="search-product-amz-marketplace"
          v-model="$parent.searchParams.amz_marketplace"
          :options="[{ value: '', label: 'AMZ Marketplace' }, ...marketPlaces.slice(1)]"
          :first-disabled="false"
          :use-choices="false"
        />
      </div>
      <div class="col-12 col-md-3 my-1">
        <argon-select
          id="search-product-category"
          v-model="$parent.searchParams.category"
          :options="[{ value: '', label: 'Category' }, ...categories.slice(1)]"
          :first-disabled="false"
          :use-choices="false"
        />
      </div>
      <div class="col-12 col-md-4 my-1">
        <div class="d-flex justify-content-between">
          <argon-input
            v-model="$parent.searchParams.query"
            class="me-2 w-100"
            placeholder="MPIN, KW, Brand, Sold By, ASIN"
          />
          <button class="btn btn-primary" type="submit" :disabled="loading">
            <i v-if="loading" class="fa fa-refresh fa-spin" />
            <i v-else class="fas fa-search" />
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import ArgonInput from '@/components/ArgonInput.vue';
import ArgonSelect from '@/components/ArgonSelect.vue';
import marketPlaces from '@/utils/marketPlaces';
import reviewTypes from '@/utils/reviewTypes';
import categories from '@/utils/categories';
import router from '@/router';
import removeEmptyFields from '@/utils/removeEmptyFields';
import { parseQueryString } from '@/utils/queryString';

export default {
  name: 'ProductFilters',
  components: { ArgonSelect, ArgonInput },
  data() {
    return {
      loading: false,
      marketPlaces,
      reviewTypes,
      categories,
    };
  },
  methods: {
    async handleSearch() {
      const search = removeEmptyFields(this.$parent.searchParams);
      const queryString = parseQueryString(search);
      router.push(`${window.location.pathname}?page=${this.$parent.currentPage}&${queryString}`);
      this.loading = true;
      await this.$parent.fetchData();
      this.loading = false;
    },
  },
};
</script>
