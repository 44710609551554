import { reactive } from 'vue';
import bootstrap from 'bootstrap/dist/js/bootstrap.min.js';
import actions from './actions';

const store = reactive({
  showWelcome: false,
  user: null,
  role: '',
  users: {
    Active: [],
    InActive: [],
    Terminated: [],
    Restricted: [],
    Pending: [],
  },
  bonuses: [],
  products: {
    Active: [],
    Archived: [],
  },
  reservations: [],
  orders: [],
  leaderboard: [],
  blacklist: [],

  // ----------------------> Stats <--------------------
  cards: [
    {
      totalCommission: 0,
      name: 'Refunded Commission',
      change: 0,
      percentageChange: 0,
    },
    {
      totalCommission: 0,
      name: 'Comsn Processing Commission',
      change: 0,
      percentageChange: 0,
    },
    {
      totalCommission: 0,
      name: 'Completed Commission',
      change: 0,
      percentageChange: 0,
    },
    {
      name: 'Active Products',
      count: 0,
      change: 0,
      percentageChange: 0,
    },
    {
      name: 'Ordered',
      count: 0,
      change: 0,
      percentageChange: 0,
    },
    {
      name: 'Reviewed',
      count: 0,
      change: 0,
      percentageChange: 0,
    },
    {
      name: 'Feedbacked',
      count: 0,
      change: 0,
      percentageChange: 0,
    },
    {
      name: 'Corrected',
      count: 0,
      change: 0,
      percentageChange: 0,
    },
    {
      name: 'On-Hold',
      count: 0,
      change: 0,
      percentageChange: 0,
    },
    {
      name: 'Refund Delayed',
      count: 0,
      change: 0,
      percentageChange: 0,
    },
    {
      name: 'Review Deleted',
      count: 0,
      change: 0,
      percentageChange: 0,
    },
    {
      name: 'Feedback Sent',
      count: 0,
      change: 0,
      percentageChange: 0,
    },
    {
      name: 'Sent to Seller',
      count: 0,
      change: 0,
      percentageChange: 0,
    },
    {
      name: 'Feedback Rfnd',
      count: 0,
      change: 0,
      percentageChange: 0,
    },
    {
      name: 'Review Awaited',
      count: 0,
      change: 0,
      percentageChange: 0,
    },
    {
      name: 'Refunded',
      count: 0,
      change: 0,
      percentageChange: 0,
    },
    {
      name: 'Cancelled',
      count: 0,
      change: 0,
      percentageChange: 0,
    },
    {
      name: 'Comsn Processing',
      count: 0,
      change: 0,
      percentageChange: 0,
    },
    {
      name: 'Completed',
      count: 0,
      change: 0,
      percentageChange: 0,
    },
    {
      name: 'Receivable Commission',
      totalCommission: 0,
    },
    {
      name: 'Received Commission',
      totalCommission: 0,
    },
  ],
  lineChart: {
    currentMonth: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    lastMonth: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
  },
  activity: [],

  // ----------------------> Configurations <--------------------
  env: process.env.VUE_APP_ENV,
  hideConfigButton: false,
  isPinned: true,
  showConfig: false,
  color: '',
  sidebarType: 'bg-white',
  darkMode: false,
  isNavFixed: false,
  isAbsolute: false,
  showNavs: true,
  showSidenav: true,
  showNavbar: true,
  showFooter: true,
  showMain: true,
  layout: 'default',
  bootstrap,
});

export { store, actions };
