<template>
  <span class="badge" :class="`${color} ${sm ? 'badge-sm' : ''}`">{{ value }}</span>
</template>

<script>
export default {
  name: 'ProductBadge',
  props: {
    value: {
      type: String,
      required: true,
    },
    sm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const colors = {
      Active: 'badge-primary',
      'Sales End': 'badge-danger',
      'Market Ends': 'badge-danger',
      Archived: 'badge-dark',
      Restricted: 'badge-danger',
    };

    return {
      color: colors[this.value],
    };
  },
};
</script>
