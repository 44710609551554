<template>
  <span class="badge badge-danger">
    {{ remaining }}
  </span>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';

const props = defineProps({
  time: {
    type: String,
    required: true,
  },
});

const remaining = ref('00:00');

// Method to calculate remaining time
const calculateRemaining = () => {
  const creationTime = new Date(props.time);
  const endTime = new Date(creationTime.getTime() + 120 * 60 * 1000); // +1 hour
  const now = new Date();
  const difference = endTime - now;

  if (difference <= 0) {
    remaining.value = 'Expired';
    return false;
  } else {
    const hours = Math.floor(difference / (3600 * 1000)); // 3600 seconds in an hour, converted to milliseconds
    const minutes = Math.floor((difference % (3600 * 1000)) / (60 * 1000));
    const seconds = Math.floor((difference % (60 * 1000)) / 1000);
    remaining.value = `${hours ? `0${hours}` : hours}:${String(minutes).padStart(2, '0')}:${String(
      seconds,
    ).padStart(2, '0')}`;
    return true;
  }
};

let interval;

onMounted(() => {
  if (calculateRemaining()) {
    interval = setInterval(() => {
      if (!calculateRemaining()) {
        clearInterval(interval);
      }
    }, 1000);
  }
});

onBeforeUnmount(() => {
  clearInterval(interval);
});

watch(
  () => props.time,
  () => {
    if (interval) {
      clearInterval(interval);
    }
    if (calculateRemaining()) {
      interval = setInterval(() => {
        if (!calculateRemaining()) {
          clearInterval(interval);
        }
      }, 1000);
    }
  },
);
</script>
