<template>
  <div class="form-group my-0">
    <div :class="hasIcon(icon)">
      <span v-if="iconDir === 'left'" class="input-group-text">
        {{ decodeHtmlEntity(icon) }}
      </span>
      <input
        v-if="type === 'file'"
        :id="id"
        :type="type"
        class="form-control"
        :class="getClasses(size, success, error)"
        accept=".png, .jpg, .jpeg, .webp, .gif"
        :name="name"
        :multiple="multiple"
        :required="isRequired"
        :disabled="isDisabled"
        @change="handleFileChange"
      />
      <input
        v-else
        :id="id"
        :type="type"
        class="form-control"
        :class="[iconDir ? 'px-1' : '', getClasses(size, success, error)]"
        :name="name"
        :value="modelValue"
        :placeholder="placeholder"
        :step="step"
        :required="isRequired"
        :disabled="isDisabled"
        @input="$emit('update:modelValue', $event.target.value)"
      />
      <span v-if="iconDir === 'right'" class="input-group-text">
        <i :class="getIcon(icon)"></i>
      </span>
    </div>
  </div>
</template>

<script>
import { decodeHtmlEntity } from '@/utils/currencies';

export default {
  name: 'ArgonInput',
  props: {
    size: {
      type: String,
      default: 'default',
    },
    success: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    iconDir: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    step: {
      type: String,
      default: 'any',
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    decodeHtmlEntity,
    getClasses: (size, success, error) => {
      let sizeValue, isValidValue;

      sizeValue = size ? `form-control-${size}` : null;

      if (error) {
        isValidValue = 'is-invalid';
      } else if (success) {
        isValidValue = 'is-valid';
      } else {
        isValidValue = '';
      }

      return `${sizeValue} ${isValidValue}`;
    },
    getIcon: (icon) => (icon ? icon : null),
    hasIcon: (icon) => (icon ? 'input-group' : null),
    handleFileChange(event) {
      const files = event.target.files;
      // If multiple files can be selected, emit the FileList, otherwise emit the first file
      if (this.multiple) {
        this.$emit('update:modelValue', files);
      } else {
        this.$emit('update:modelValue', files[0]);
      }
    },
  },
};
</script>
