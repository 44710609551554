<template>
  <div class="card multisteps-form__panel p-3 border-radius-xl bg-white" data-animation="FadeIn">
    <h5 class="font-weight-bolder mb-0">Referral Info</h5>
    <p class="mb-0 text-sm">If the user has any referral, add here.</p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-sm-12 col-md-6 my-1">
          <label htmlFor="newuser-referral-name">Referral Name</label>
          <argon-input
            id="newuser-referral-name"
            v-model="$parent.user.referral.name"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. Paul"
          />
        </div>
        <div class="col-sm-12 col-md-6 my-1">
          <label htmlFor="newuser-referral-contact">Referral Contact</label>
          <argon-input
            id="newuser-referral-contact"
            v-model="$parent.user.referral.contact"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. +92 123 4567890"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 my-1">
          <label htmlFor="newuser-referral-email">Referral UserID/Email</label>
          <argon-input
            id="newuser-referral-email"
            v-model="$parent.user.referral.email"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. abc@xyz.com"
          />
        </div>
      </div>
      <div class="button-row d-flex mt-3">
        <argon-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="$parent.prevStep"
        >
          Prev
        </argon-button>
        <argon-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto js-btn-next"
          @click="$parent.nextStep"
        >
          Next
        </argon-button>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonButton from '@/components/ArgonButton.vue';
import ArgonInput from '@/components/ArgonInput.vue';

export default {
  name: 'UserReferral',
  components: {
    ArgonInput,
    ArgonButton,
  },
};
</script>
