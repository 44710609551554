<template>
  <div class="col-sm-3 my-2">
    <div class="card">
      <div class="p-3 card-body position-relative">
        <div class="row">
          <div class="col-7 text-start">
            <p class="text-sm mb-1 text-uppercase font-weight-bold">
              {{ card.name }}
            </p>
            <h5 class="mb-0 font-weight-bolder">{{ card.count }}</h5>
            <span
              :class="`text-${getColor(card.percentageChange)}`"
              class="mt-auto mb-0 text-sm text-end font-weight-bolder"
            >
              {{ getValue(parseInt(card.percentageChange)) }}
              <span class="font-weight-normal text-secondary">since last month</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MiniStatisticsCard',
  props: {
    card: {
      type: Object,
      required: true,
      name: String,
      count: Number,
      percentageChange: Number,
    },
    invert: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Default Statistics Card',
    },
    count: {
      type: String,
      default: '',
    },
    menu: {
      type: String,
      default: '',
    },
    percentage: {
      type: Object,
      default: () => {},
      color: String,
      value: String,
      label: String,
    },
    dropdown: {
      type: Array,
      default: () => [],
      label: String,
      route: String,
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    getColor(value) {
      if (value > 0) {
        if (this.invert) {
          return 'danger';
        } else {
          return 'success';
        }
      } else if (value < 0) {
        if (this.invert) {
          return 'success';
        } else {
          return 'danger';
        }
      } else {
        return 'secondary';
      }
    },
    getValue(value) {
      if (value > 0) {
        return value > 1000 ? '+999%' : `+${value}%`;
      } else if (value < 0) {
        return `${value}%`;
      } else {
        return `${value}%`;
      }
    },
  },
};
</script>
