<template>
  <div class="py-4 px-1 px-sm-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div class="mt-4 card card-body">
          <div class="d-flex justify-content-between">
            <div>
              <h6 class="mb-0">Submit Order</h6>
              <p class="mb-0 text-sm d-none d-sm-block">Submit a new order to the product</p>
            </div>
            <div>
              <h6 class="mb-0 d-inline-block">Reservation Time:&nbsp;</h6>
              <ReservationTime v-if="reservation.createdAt" :time="reservation.createdAt" />
            </div>
          </div>
          <hr class="my-3 horizontal dark" />
          <div class="row mt-3">
            <div class="col-md-6 my-1">
              <label class="form-label" htmlFor="neworder-review-format">Review Format *</label>
              <argon-select
                v-if="reviewFormats.length"
                id="neworder-review-format"
                v-model="order.review_format"
                :options="reviewFormats"
              />
            </div>
            <div class="col-md-6 my-1">
              <label class="form-label" htmlFor="neworder-amz-order">AMZ Order Number *</label>
              <argon-input
                id="neworder-amz-order"
                v-model="order.amz_order_number"
                type="text"
                placeholder="Enter AMZ order number"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 my-1">
              <label class="form-label" htmlFor="neworder-buyer-paypal">Buyer PayPal *</label>
              <argon-input
                id="neworder-buyer-paypal"
                v-model="order.buyer_paypal"
                type="email"
                placeholder="Enter buyer Paypal email"
              />
            </div>
            <div class="col-md-6 my-1">
              <label class="form-label" htmlFor="neworder-buyer-name">Buyer Name</label>
              <argon-input
                id="neworder-buyer-name"
                v-model="order.buyer_name"
                type="text"
                placeholder="Enter product buyer name"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 my-1">
              <label class="form-label" htmlFor="neworder-order-screenshot">
                Order Screenshot *
              </label>
              <argon-input
                id="neworder-order-screenshot"
                v-model="order.order_screenshot"
                type="file"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 my-1">
              <argon-textarea
                id="neworder-comment"
                v-model="order.comment"
                rows="3"
                placeholder="Enter comments"
                class="mt-0"
              >
                Comments
              </argon-textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-12 my-1">
              <a
                id="neworder-prodinfo"
                href="#collapseProductInfo"
                data-bs-toggle="collapse"
                class="col-12 p-3 badge bg-primary text-white d-flex justify-content-between"
                style="text-align: left"
              >
                Product Information
                <i id="toggleIcon" class="fas fa-angle-down"></i>
              </a>
              <div id="collapseProductInfo" class="collapse">
                <div class="card-body bg-gray-100">
                  <div class="row">
                    <div class="col-6">
                      <h6 class="my-0">AMZ Marketplace:</h6>
                      <p class="text-sm">
                        {{ reservation.product?.amz_marketplace }}
                        <span v-show="reservation.product?.market_type !== 'Amazon'">
                          {{ reservation.product?.market_type }}
                        </span>
                      </p>
                    </div>
                    <div class="col-6">
                      <h6 class="my-0">Manager:</h6>
                      <router-link
                        :to="`/users/${reservation.manager?.user_id}`"
                        class="text-primary text-sm"
                      >
                        {{ reservation.manager?.first_name }} {{ reservation.manager?.last_name }}
                      </router-link>
                      <WALink :phone="reservation.manager?.whatsapp_number" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <h6 class="my-0">Keywords:</h6>
                      <p class="text-sm">{{ reservation.product?.keywords }}</p>
                    </div>
                    <div class="col-6">
                      <h6 class="my-0">Product MPIN#:</h6>
                      <p class="text-sm">
                        {{ reservation.product?.product_id }}
                        <router-link
                          :to="`/products/${reservation.product?.product_id}`"
                          class="text-primary"
                        >
                          (View Details)
                        </router-link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--    Submit/Cancel      -->
          <div class="mt-4 d-flex justify-content-end">
            <router-link role="button" class="m-0 btn btn-light" to="/reservations">
              Cancel
            </router-link>
            <button
              type="button"
              name="button"
              class="m-0 btn bg-gradient-primary ms-2"
              @click="handleSubmit"
              :disabled="loading"
            >
              <i v-if="loading" class="fa fa-refresh fa-spin" />
              <span v-else>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonInput from '@/components/ArgonInput.vue';
import ArgonSelect from '@/components/ArgonSelect.vue';
import ArgonTextarea from '@/components/ArgonTextarea.vue';
import { useToast } from 'vue-toastification';
import to from 'await-to-js';
import router from '@/router';
import { useRoute } from 'vue-router';
import ReservationTime from '@/components/ReservationTime.vue';
import removeEmptyFields from '@/utils/removeEmptyFields';
import createFormData from '@/utils/createFormData';
import WALink from '@/components/WALink.vue';
const toast = useToast();

export default {
  name: 'NewOrder',
  components: {
    WALink,
    ReservationTime,
    ArgonTextarea,
    ArgonInput,
    ArgonSelect,
  },
  inject: ['store', 'actions'],
  data() {
    const { params } = useRoute();

    return {
      loading: false,
      reservationId: params.reservationId,
      reservation: {},
      reviewFormats: [],
      order: {
        review_format: '',
        amz_order_number: '',
        buyer_paypal: '',
        buyer_name: '',
        comment: '',
        order_screenshot: '',
      },
    };
  },
  mounted() {
    this.fetchData();

    const aElement = document.getElementById('neworder-prodinfo');
    const collapseElement = document.getElementById('collapseProductInfo');
    const toggleIcon = document.getElementById('toggleIcon');

    collapseElement.addEventListener('show.bs.collapse', function () {
      toggleIcon.classList.remove('fa-angle-down');
      toggleIcon.classList.add('fa-angle-up');
      aElement.style.borderBottomRightRadius = '0px';
      aElement.style.borderBottomLeftRadius = '0px';
    });

    collapseElement.addEventListener('hide.bs.collapse', function () {
      toggleIcon.classList.remove('fa-angle-up');
      toggleIcon.classList.add('fa-angle-down');
      aElement.style.borderBottomRightRadius = '5px';
      aElement.style.borderBottomLeftRadius = '5px';
    });
  },
  methods: {
    async fetchData() {
      const [, data] = await to(this.actions.api('get', `/reservations/${this.reservationId}`));

      if (!data?.reservation) {
        router.push('/dashboard');
        return toast.error('No reservation found');
      }

      const reviewType = data.reservation.product.review_type;
      const commission = data.reservation.product.commission;

      if (['Feedback', 'Rating', 'No Review'].includes(reviewType)) {
        this.reviewFormats = [
          { value: reviewType, label: `${reviewType} (Commission: Rs.${commission.marketer})` },
        ];
        this.order.review_format = reviewType;
      } else {
        this.reviewFormats = Object.keys(commission).map((key, i) => {
          if (i === 0) {
            this.order.review_format = key;
          }
          return { value: key, label: `${key} (Commission: Rs.${commission[key].marketer})` };
        });
      }

      this.reservation = { ...this.reservation, ...data.reservation };
    },
    async handleSubmit() {
      let data = removeEmptyFields({ ...this.order });

      const validated = [
        'review_format',
        'amz_order_number',
        'buyer_paypal',
        'order_screenshot',
      ].every((val) => !!data[val]);

      if (!validated) {
        return toast.error('Fields with * are required');
      }

      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.buyer_paypal)) {
        return toast.error('Buyer PayPal is not valid');
      }

      const formData = createFormData(data);

      this.loading = true;
      const [, res] = await to(
        this.actions.api('post', `/orders/${this.reservation._id}`, formData),
      );
      this.loading = false;

      if (res?.order) {
        toast.success('Order has been submitted');
        router.push('/orders');
      }
    },
  },
};
</script>
