const categories = [
  { value: '', label: 'Select product category' },
  { value: 'Arts & Crafts', label: 'Arts & Crafts' },
  { value: 'Automotive', label: 'Automotive' },
  { value: 'Baby', label: 'Baby' },
  { value: 'Beauty & Personal Care', label: 'Beauty & Personal Care' },
  { value: 'Books', label: 'Books' },
  { value: `Boys' Fashion`, label: `Boys' Fashion` },
  { value: 'Cell Phones & Accessories', label: 'Cell Phones & Accessories' },
  { value: 'Computers & Accessories', label: 'Computers & Accessories' },
  { value: 'Electronics', label: 'Electronics' },
  { value: `Girls' Fashion`, label: `Girls' Fashion` },
  { value: 'Health & Household', label: 'Health & Household' },
  { value: 'Home & Kitchen', label: 'Home & Kitchen' },
  { value: 'Industrial & Scientific', label: 'Industrial & Scientific' },
  { value: 'Jewellery', label: 'Jewellery' },
  { value: 'Luggage', label: 'Luggage' },
  { value: `Men' Fashion`, label: `Men' Fashion` },
  { value: 'Office Products', label: 'Office Products' },
  { value: 'Other / Misc', label: 'Other / Misc' },
  { value: 'Patio, Lawn & Garden', label: 'Patio, Lawn & Garden' },
  { value: 'Pet Supplies', label: 'Pet Supplies' },
  { value: 'Sports & Outdoors', label: 'Sports & Outdoors' },
  { value: 'Tools & Home Improvement', label: 'Tools & Home Improvement' },
  { value: 'Toys & Games', label: 'Toys & Games' },
  { value: 'Video Games', label: 'Video Games' },
  { value: `Women' Fashion`, label: `Women' Fashion` },
];

export default categories;
