<template>
  <form @submit.prevent="handleSubmit">
    <div class="py-4 px-2 px-sm-4 container-fluid">
      <div class="row text-center text-sm-start">
        <div class="col-lg-6">
          <h4 class="text-white">Order ID# {{ orderId }}</h4>
        </div>
        <div class="text-right col-lg-6 d-flex flex-column justify-content-center">
          <div class="mt-2 mb-0 me-lg-0 ms-lg-auto mt-lg-0">
            <button v-if="editing" type="submit" class="btn btn-light mx-2" :disabled="loading">
              <i v-if="loading" class="fa fa-refresh fa-spin" />
              <span v-else>Save</span>
            </button>
            <button
              type="button"
              class="btn btn-outline-white"
              @click="
                () => {
                  editing = !editing;
                  fetchData();
                }
              "
            >
              {{ editing ? 'Cancel' : 'Edit' }}
            </button>
          </div>
        </div>
      </div>

      <!--  Images  -->
      <div class="mt-4 row">
        <div class="col-lg-4">
          <!--   Order Screenshot     -->
          <div class="card">
            <div class="card-body">
              <h5 class="font-weight-bolder">Order Screenshot</h5>
              <div class="row">
                <div class="col-12">
                  <img
                    class="mt-3 shadow-lg w-100 border-radius-lg"
                    :src="`${mediaUrl}${order.order_screenshot}`"
                    alt="Order Screenshot"
                  />
                </div>
                <div class="mt-5 col-12">
                  <div class="d-flex">
                    <label
                      class="mb-0 btn bg-gradient-primary btn-sm me-2"
                      htmlFor="vieworder-order_screenshot"
                      title="Edit order screenshot"
                    >
                      Edit
                    </label>
                  </div>
                </div>
                <input
                  id="vieworder-order_screenshot"
                  type="file"
                  name="order_screenshot"
                  accept=".png, .jpg, .jpeg, .webp, .gif"
                  hidden
                  @change="uploadImage"
                />
              </div>
            </div>
          </div>

          <!--   Review Screenshot     -->
          <div v-if="order.review_screenshot || store.role === 'Marketer'" class="mt-3 card">
            <div class="card-body">
              <h5 class="font-weight-bolder" :class="!order.review_screenshot && 'text-center'">
                Review Screenshot
              </h5>
              <div v-if="order.review_screenshot" class="row">
                <div class="col-12">
                  <img
                    class="mt-3 shadow-lg w-100 border-radius-lg"
                    :src="`${mediaUrl}${order.review_screenshot}`"
                    alt="Review Screenshot"
                  />
                </div>

                <div v-if="store.role === 'Marketer'" class="mt-5 col-12">
                  <div class="d-flex">
                    <label
                      class="mb-0 btn bg-gradient-primary btn-sm me-2"
                      htmlFor="vieworder-review_screenshot"
                      title="Edit Review screenshot"
                    >
                      Edit
                    </label>
                    <button
                      class="mb-0 btn btn-outline-dark btn-sm"
                      type="button"
                      name="button"
                      @click="removeImage('review_screenshot')"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
              <div v-else-if="store.role === 'Marketer'" class="row mt-3">
                <div class="d-flex justify-content-center">
                  <label
                    class="mb-0 btn bg-gradient-primary btn-lg me-2"
                    htmlFor="vieworder-review_screenshot"
                    title="Add Review screenshot"
                  >
                    Add
                  </label>
                </div>
              </div>
              <input
                v-if="store.role === 'Marketer'"
                id="vieworder-review_screenshot"
                type="file"
                name="review_screenshot"
                accept=".png, .jpg, .jpeg, .webp, .gif"
                hidden
                @change="uploadImage"
              />
            </div>
          </div>

          <!--   Refund Screenshot     -->
          <div v-if="order.refund_screenshot || store.role !== 'Marketer'" class="mt-3 card">
            <div class="card-body">
              <h5 class="font-weight-bolder" :class="!order.refund_screenshot && 'text-center'">
                Refund Screenshot
              </h5>
              <div v-if="order.refund_screenshot" class="row">
                <div class="col-12">
                  <img
                    class="mt-3 shadow-lg w-100 border-radius-lg"
                    :src="`${mediaUrl}${order.refund_screenshot}`"
                    alt="Refund Screenshot"
                  />
                </div>

                <div v-if="store.role !== 'Marketer'" class="mt-5 col-12">
                  <div class="d-flex">
                    <label
                      class="mb-0 btn bg-gradient-primary btn-sm me-2"
                      htmlFor="vieworder-refund_screenshot"
                      title="Edit Refund screenshot"
                    >
                      Edit
                    </label>
                    <button
                      class="mb-0 btn btn-outline-dark btn-sm"
                      type="button"
                      name="button"
                      @click="removeImage('refund_screenshot')"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
              <div v-else-if="store.role !== 'Marketer'" class="row mt-3">
                <div class="d-flex justify-content-center">
                  <label
                    class="mb-0 btn bg-gradient-primary btn-lg me-2"
                    htmlFor="vieworder-refund_screenshot"
                    title="Add Refund screenshot"
                  >
                    Add
                  </label>
                </div>
              </div>
              <input
                v-if="store.role !== 'Marketer'"
                id="vieworder-refund_screenshot"
                type="file"
                name="refund_screenshot"
                accept=".png, .jpg, .jpeg, .webp, .gif"
                hidden
                @change="uploadImage"
              />
            </div>
          </div>

          <!--   Feedback Screenshot     -->
          <div v-if="order.feedback_screenshot || store.role === 'Marketer'" class="mt-3 card">
            <div class="card-body">
              <h5 class="font-weight-bolder" :class="!order.feedback_screenshot && 'text-center'">
                Feedback Screenshot
              </h5>
              <div v-if="order.feedback_screenshot" class="row">
                <div class="col-12">
                  <img
                    class="mt-3 shadow-lg w-100 border-radius-lg"
                    :src="`${mediaUrl}${order.feedback_screenshot}`"
                    alt="Feedback Screenshot"
                  />
                </div>

                <div v-if="store.role === 'Marketer'" class="mt-5 col-12">
                  <div class="d-flex">
                    <label
                      class="mb-0 btn bg-gradient-primary btn-sm me-2"
                      htmlFor="vieworder-feedback_screenshot"
                      title="Edit Feedback screenshot"
                    >
                      Edit
                    </label>
                    <button
                      class="mb-0 btn btn-outline-dark btn-sm"
                      type="button"
                      name="button"
                      @click="removeImage('feedback_screenshot')"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
              <div v-else-if="store.role === 'Marketer'" class="row mt-3">
                <div class="d-flex justify-content-center">
                  <label
                    class="mb-0 btn bg-gradient-primary btn-lg me-2"
                    htmlFor="vieworder-feedback_screenshot"
                    title="Add Feedback screenshot"
                  >
                    Add
                  </label>
                </div>
              </div>
              <input
                v-if="store.role === 'Marketer'"
                id="vieworder-feedback_screenshot"
                type="file"
                name="feedback_screenshot"
                accept=".png, .jpg, .jpeg, .webp, .gif"
                hidden
                @change="uploadImage"
              />
            </div>
          </div>
        </div>
        <div class="mt-4 col-lg-8 mt-lg-0">
          <!--   Basic Info     -->
          <div class="card">
            <div class="card-body">
              <div class="d-flex">
                <h5 class="font-weight-bolder">Order Information</h5>
                <span class="ms-2" title="Copy info" @click="copyInfo(order)">
                  <i class="fas fa-copy text-secondary cursor-pointer" />
                </span>
              </div>
              <p v-show="editing" class="mb-0 text-sm">Fields with * are mandatory</p>

              <div class="row mt-3">
                <div class="col-md-6 my-1">
                  <label class="form-label" htmlFor="vieworder-review-type">
                    Review Type {{ editing ? '*' : '' }}
                  </label>
                  <argon-select
                    id="vieworder-review-type"
                    v-model="order.review_format"
                    :is-disabled="!editing"
                    :use-choices="false"
                    :options="reviewFormats"
                  />
                </div>
                <div class="col-md-6 my-1">
                  <label class="form-label" htmlFor="vieworder-amz-order">
                    AMZ Order Number {{ editing ? '*' : '' }}
                  </label>
                  <argon-input
                    id="vieworder-amz-order"
                    v-model="order.amz_order_number"
                    type="text"
                    placeholder="Enter AMZ order number"
                    :is-disabled="!editing"
                  />
                </div>
              </div>
              <div class="row">
                <div v-if="!editing || store.role !== 'Marketer'" class="col-md-6 my-1">
                  <label class="form-label" htmlFor="vieworder-buyer-paypal">
                    Buyer PayPal {{ editing ? '*' : '' }}
                  </label>
                  <argon-input
                    id="vieworder-buyer-paypal"
                    v-model="order.buyer_paypal"
                    type="email"
                    placeholder="Enter buyer Paypal email"
                    :is-disabled="!editing"
                  />
                </div>
                <div
                  class="my-1"
                  :class="!editing || store.role !== 'Marketer' ? 'col-md-6' : 'col-12'"
                >
                  <label class="form-label" htmlFor="vieworder-buyer-name">Buyer Name</label>
                  <argon-input
                    id="vieworder-buyer-name"
                    v-model="order.buyer_name"
                    type="text"
                    placeholder="Enter product buyer name"
                    :is-disabled="!editing"
                  />
                </div>
              </div>

              <div v-show="!editing" class="row">
                <div class="col-12 my-1">
                  <label class="form-label">Order Status</label>
                  <argon-input type="text" is-disabled v-model="order.status" />
                </div>
              </div>
              <div v-show="!editing" class="row">
                <div class="col-md-6 my-1">
                  <label class="form-label">Created At</label>
                  <input
                    type="text"
                    class="form-control"
                    :value="new Date(order.createdAt)?.toLocaleString()"
                    disabled
                  />
                </div>
                <div class="col-md-6 my-1">
                  <label class="form-label">Updated At</label>
                  <input
                    type="text"
                    class="form-control"
                    :value="new Date(order.updatedAt)?.toLocaleString()"
                    disabled
                  />
                </div>
              </div>

              <div v-show="!editing" class="row mt-3">
                <div class="col-12">
                  <a
                    id="vieworder-prodinfo"
                    href="#vieworder-collapseProductInfo"
                    data-bs-toggle="collapse"
                    class="col-12 p-3 badge bg-primary text-white d-flex justify-content-between"
                    style="text-align: left"
                  >
                    Product Information
                    <i id="vieworder-toggleIcon" class="fas fa-angle-down"></i>
                  </a>
                  <div id="vieworder-collapseProductInfo" class="collapse">
                    <div class="card-body bg-gray-100">
                      <div class="row">
                        <div class="col-6">
                          <h6 class="my-0">AMZ Marketplace:</h6>
                          <p class="text-sm">
                            {{ order.product?.amz_marketplace }}
                            <span v-show="order.product?.market_type !== 'Amazon'">
                              {{ order.product?.market_type }}
                            </span>
                          </p>
                        </div>
                        <div class="col-6">
                          <h6 class="my-0">Manager:</h6>
                          <router-link
                            :to="`/users/${order.manager?.user_id}`"
                            class="text-primary text-sm"
                          >
                            {{ order.manager?.first_name }} {{ order.manager?.last_name }}
                          </router-link>
                          <WALink :phone="order.manager?.whatsapp_number" />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <h6 class="my-0">Keywords:</h6>
                          <p class="text-sm">{{ order.product?.keywords }}</p>
                        </div>
                        <div class="col-6">
                          <h6 class="my-0">Product MPIN#:</h6>
                          <p class="text-sm">
                            {{ order.product?.product_id }}
                            <router-link
                              :to="`/products/${order.product?.product_id}`"
                              class="text-primary"
                            >
                              (View Details)
                            </router-link>
                          </p>
                        </div>
                      </div>
                      <div v-show="store.role !== 'Marketer'" class="row">
                        <div class="col-12">
                          <h6 class="my-0">Seller Code:</h6>
                          <p class="text-sm">{{ order.product?.seller_code }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!--    Change status    -->
          <div v-show="editing" class="mt-3 card">
            <div class="card-body">
              <h5 class="font-weight-bolder">Status / Comment</h5>

              <div class="row mt-3">
                <div class="col-12">
                  <argon-textarea
                    id="vieworder-comment"
                    v-model="order.comment"
                    @keydown="handleKeydown"
                    :rows="3"
                    placeholder="Enter comments"
                    class="mt-0"
                    :is-disabled="!editing"
                  >
                    Comments
                  </argon-textarea>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <label class="form-label" htmlFor="vieworder-order-status">
                    Order Status {{ editing ? '*' : '' }}
                  </label>
                  <argon-select
                    id="vieworder-order-status"
                    v-model="order.status"
                    :use-choices="false"
                    :is-disabled="!editing"
                    :options="
                      statusRights[store.role].includes(order.status)
                        ? statuses
                        : [{ value: order.status, label: order.status }, ...statuses]
                    "
                  />
                </div>
              </div>
            </div>
          </div>

          <OrderActivity
            title="Activity / Comments"
            :headers="['Actor', 'Comment', 'Action']"
            :history="order.activity"
          />
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import ArgonSelect from '@/components/ArgonSelect.vue';
import ArgonInput from '@/components/ArgonInput.vue';
import ArgonTextarea from '@/components/ArgonTextarea.vue';
import OrderActivity from '@/views/orders/component/OrderActivity.vue';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import to from 'await-to-js';
import router from '@/router';
import removeEmptyFields from '@/utils/removeEmptyFields';
import statusRights from '@/utils/orderStatusRights';
import WALink from '@/components/WALink.vue';
const toast = useToast();

export default {
  name: 'ViewOrder',
  components: { WALink, OrderActivity, ArgonTextarea, ArgonInput, ArgonSelect },
  inject: ['store', 'actions'],
  data() {
    const { query, params } = useRoute();
    return {
      editing: query.edit || false,
      mediaUrl: process.env.VUE_APP_MEDIA_URL,
      loading: false,
      orderId: params.orderId,
      reviewFormats: [],
      statuses: [],
      statusRights,
      order: {
        review_format: '',
        amz_order_number: '',
        buyer_paypal: '',
        buyer_name: '',
        comment: '',
        order_screenshot: '',
        status: '',
        activity: [],
        createdAt: '',
        updatedAt: '',
      },
    };
  },
  mounted() {
    this.fetchData();
    this.statuses = statusRights[this.store.role].map((item) => ({ value: item, label: item }));

    const aElement = document.getElementById('vieworder-prodinfo');
    const collapseElement = document.getElementById('vieworder-collapseProductInfo');
    const toggleIcon = document.getElementById('vieworder-toggleIcon');

    collapseElement.addEventListener('show.bs.collapse', function () {
      toggleIcon.classList.remove('fa-angle-down');
      toggleIcon.classList.add('fa-angle-up');
      aElement.style.borderBottomRightRadius = '0px';
      aElement.style.borderBottomLeftRadius = '0px';
    });

    collapseElement.addEventListener('hide.bs.collapse', function () {
      toggleIcon.classList.remove('fa-angle-up');
      toggleIcon.classList.add('fa-angle-down');
      aElement.style.borderBottomRightRadius = '5px';
      aElement.style.borderBottomLeftRadius = '5px';
    });
  },
  methods: {
    async fetchData() {
      const [, data] = await to(this.actions.api('get', `/orders/${this.orderId}`));

      if (!data?.order) {
        router.push('/dashboard');
        return toast.error('No order found');
      }

      const reviewType = data.order.product.review_type;
      const commission = data.order.product.commission;

      if (['Feedback', 'Rating', 'No Review'].includes(reviewType)) {
        this.reviewFormats = [
          { value: reviewType, label: `${reviewType} (Commission: Rs.${commission.marketer})` },
        ];
      } else {
        this.reviewFormats = Object.keys(commission).map((key, i) => {
          return { value: key, label: `${key} (Commission: Rs.${commission[key].marketer})` };
        });
      }

      this.order = { ...this.order, ...data.order, activity: data.order.activity.reverse() };
    },
    async handleSubmit() {
      let data = removeEmptyFields({ ...this.order });

      const validated = [
        'review_format',
        'amz_order_number',
        'buyer_paypal',
        'order_screenshot',
        'status',
      ].every((val) => !!data[val]);

      if (!validated) {
        return toast.error('Fields with * are required');
      }

      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.buyer_paypal)) {
        return toast.error('Buyer PayPal is not valid');
      }

      this.loading = true;
      const [, res] = await to(this.actions.api('put', `/orders/${this.order._id}`, data));
      this.loading = false;

      if (res?.order) {
        toast.success('Order has been updated');
        this.editing = false;
      }
    },
    async uploadImage(e) {
      if (!e.target.files?.length) {
        return;
      }

      const formData = new FormData();
      formData.append(e.target.name, e.target.files[0]);
      formData.append('field', e.target.name);

      const [, data] = await to(
        this.actions.api('put', `/orders/update-image/${this.order._id}`, formData),
      );

      document.getElementById(e.target.id).value = '';

      if (data?.success) {
        toast.success('Picture updated successfully');
        this.fetchData();
      }
    },
    async removeImage(field) {
      const formData = new FormData();
      formData.append('field', field);

      const [, data] = await to(
        this.actions.api('put', `/orders/update-image/${this.order._id}`, formData),
      );

      if (data?.success) {
        toast.success('Picture removed successfully');
        this.fetchData();
      }
    },
    copyInfo(order) {
      if (!order) {
        return;
      }

      // Making Clipboard
      const clipboard = `Keywords: ${order.product?.keywords} \n\nOrder Number: ${order.amz_order_number} \n\nBuyer PayPal: ${order.buyer_paypal}`;

      // Clipboard Function
      navigator.clipboard.writeText(clipboard).then(
        function () {
          toast.success('Text copied to your clipboard.');
          console.log('Async: Copying to clipboard was successful!');
        },
        function (err) {
          toast.error('Could not copy text.');
          console.error('Async: Could not copy text: ', err);
        },
      );
    },
    handleKeydown(event) {
      // Check if the Enter key was pressed without the Shift key
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault(); // Prevent the default action (adding a new line)
        this.handleSubmit(); // Call the method to submit the form
      }
    },
  },
};
</script>
