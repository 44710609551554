<template>
  <span class="badge" :class="`${color} ${sm ? 'badge-sm' : ''}`">{{ value }}</span>
</template>

<script>
export default {
  name: 'OrderBadge',
  props: {
    value: {
      type: String,
      required: true,
    },
    sm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const colors = {
      Ordered: 'badge-primary',
      Reviewed: 'badge-info',
      Feedbacked: 'badge-success',
      Corrected: 'badge-success',
      'On-Hold': 'badge-danger',
      'Refund Delayed': 'badge-danger',
      'Review Deleted': 'badge-danger',
      'Feedback Sent': 'badge-info',
      'Sent to Seller': 'badge-success',
      'Feedback Rfnd': 'badge-info',
      'Review Awaited': 'badge-warning',
      Refunded: 'badge-success',
      Cancelled: 'badge-danger',
      'Comsn Processing': 'badge-success',
      Completed: 'badge-dark',
    };

    return {
      color: colors[this.value],
    };
  },
};
</script>
