const orderStatus = [
  { value: '', label: 'Order status' },
  { value: 'Ordered', label: 'Ordered' },
  { value: 'Reviewed', label: 'Reviewed' },
  { value: 'Feedbacked', label: 'Feedbacked' },
  { value: 'Corrected', label: 'Corrected' },
  { value: 'On-Hold', label: 'On-Hold' },
  { value: 'Refund Delayed', label: 'Refund Delayed' },
  { value: 'Review Deleted', label: 'Review Deleted' },
  { value: 'Feedback Sent', label: 'Feedback Sent' },
  { value: 'Sent to Seller', label: 'Sent to Seller' },
  { value: 'Feedback Rfnd', label: 'Feedback Rfnd' },
  { value: 'Review Awaited', label: 'Review Awaited' },
  { value: 'Refunded', label: 'Refunded' },
  { value: 'Cancelled', label: 'Cancelled' },
  { value: 'Comsn Processing', label: 'Comsn Processing' },
  { value: 'Completed', label: 'Completed' },
];

export default orderStatus;
