<template>
  <main class="main-content mt-0">
    <div
      class="page-header min-vh-100"
      style="
        background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/error-500.jpg');
      "
    >
      <span class="mask bg-gradient-warning opacity-4"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-7 mx-auto text-center">
            <h1 class="display-1 text-bolder text-white fadeIn1 fadeInBottom mt-5">Error 500</h1>
            <h2 class="fadeIn3 fadeInBottom mt-3 text-white">Something went wrong</h2>
            <p class="lead fadeIn2 fadeInBottom text-white">
              We suggest you to go to the homepage while we solve this issue.
            </p>
            <argon-button color="warning" variant="gradient" class="mt-4 fadeIn2 fadeInBottom"
              >Go to Homepage</argon-button
            >
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>
<script>
import AppFooter from '@/components/AuthFooter.vue';
import ArgonButton from '@/components/ArgonButton.vue';
export default {
  name: 'SignupBasic',
  components: {
    AppFooter,
    ArgonButton,
  },
  inject: ['store', 'actions'],
  created() {
    this.store.hideConfigButton = true;
    this.actions.toggleDefaultLayout();
  },
  beforeUnmount() {
    this.store.hideConfigButton = false;
    this.actions.toggleDefaultLayout();
  },
};
</script>
