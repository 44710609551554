<template>
  <Welcome v-if="store.showWelcome" />
  <sidenav v-if="store.showSidenav" />
  <main class="main-content position-relative max-height-vh-100 h-100">
    <navbar v-if="store.showNavbar" />
    <router-view />
    <app-footer v-show="store.showFooter" />
    <configurator
      :class="[store.showConfig ? 'show' : '', store.hideConfigButton ? 'd-none' : '']"
    />
  </main>
</template>
<script>
import Sidenav from '@/components/Sidenav';
import Configurator from '@/components/Configurator.vue';
import Navbar from '@/components/Navbar.vue';
import AppFooter from '@/components/Footer.vue';
import Welcome from '@/components/Welcome.vue';

export default {
  name: 'App',
  components: {
    Welcome,
    Sidenav,
    Configurator,
    Navbar,
    AppFooter,
  },
  inject: ['store'],
};
</script>
