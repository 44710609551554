<template>
  <div class="mt-3 card">
    <div class="pb-0 card-header">
      <h6>{{ title }}</h6>
    </div>
    <div class="px-2 pt-0 pb-2 card-body">
      <div class="table-responsive">
        <table class="table mb-0 align-items-center">
          <thead>
            <tr>
              <th
                v-for="(header, index) of headers"
                :key="index"
                :class="index > 1 ? 'text-center ps-2' : ''"
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="({ actor, comment, from, to, date }, index) in history" :key="index">
              <td style="height: 50px">
                <p class="text-left text-sm font-weight-bold py-0 my-0">
                  {{ actor }}
                </p>
                <p class="text-left text-sm py-0 my-0">
                  {{ new Date(date).toLocaleString() }}
                </p>
              </td>
              <td style="height: 50px">
                <p class="text-left text-sm py-0 my-0" :title="comment">
                  {{ actions.shortenString(comment, 70) }}
                </p>
              </td>
              <td style="height: 50px">
                <p v-if="from" class="text-left text-sm py-0 my-0">
                  <order-badge :value="from" sm />
                  to
                  <order-badge :value="to" sm />
                </p>
                <p v-else class="text-center text-sm py-0 my-0">
                  <span class="text-sm">N/A</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import OrderBadge from '@/components/OrderBadge.vue';
export default {
  name: 'OrderActivity',
  inject: ['actions'],
  components: {
    OrderBadge,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    history: {
      type: Array,
      required: true,
    },
  },
};
</script>
