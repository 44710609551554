<template>
  <select
    :id="id"
    v-model="modelValue"
    :name="name"
    class="form-control"
    :class="[!isDisabled ? 'form-select' : '', !modelValue ? 'text-secondary' : '']"
    :required="isRequired"
    :disabled="isDisabled"
    @change="$emit('update:modelValue', $event.target.value)"
  >
    <option
      v-for="(option, i) in options"
      :key="i"
      :value="option.value"
      :disabled="!option.value && firstDisabled"
    >
      {{ option.label }}
    </option>
  </select>
</template>

<script>
import Choices from 'choices.js';

export default {
  name: 'ArgonSelect',
  props: {
    name: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      default: '',
    },
    options: Array,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isSearchable: {
      type: Boolean,
      default: false,
    },
    useChoices: {
      type: Boolean,
      default: true,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    firstDisabled: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  mounted() {
    if (document.getElementById(this.id) && this.useChoices) {
      var element = document.getElementById(this.id);
      new Choices(element, {
        searchEnabled: this.isSearchable,
        allowHTML: true,
        shouldSort: false,
      });
    }
  },
};
</script>
