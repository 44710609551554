<template>
  <a
    v-if="phone"
    :href="`https://wa.me/${parsePhone(phone)}`"
    class="ms-1"
    target="_blank"
    :title="parsePhone(phone)"
  >
    <i class="fab fa-whatsapp" style="color: #25d366; transform: scale(1.1)"></i>
  </a>
</template>

<script>
export default {
  name: 'WALink',
  props: {
    phone: {
      type: String,
      required: true,
    },
  },
  methods: {
    parsePhone(phone) {
      if (!phone?.includes('+')) {
        return phone[0] === '0' ? phone.replace('0', '+92') : `+92${phone}`;
      }
      return phone;
    },
  },
};
</script>
