<template>
  <div class="py-4 px-1 px-sm-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="d-lg-flex">
              <div>
                <h5 class="mb-0 text-center text-sm-start">
                  {{ leaderboardMonth }}
                </h5>
              </div>
            </div>
          </div>
          <leaderboard-table />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LeaderboardTable from './components/LeaderboardTable.vue';
import to from 'await-to-js';

export default {
  name: 'Leaderboard',
  components: { LeaderboardTable },
  inject: ['store', 'actions'],
  data() {
    return {
      loading: false,
      leaderboardMonth: new Date(new Date().setMonth(new Date().getMonth() - 1)).toLocaleDateString(
        'en-US',
        { month: 'long', year: 'numeric' },
      ),
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const [, data] = await to(this.actions.api('get', '/leaderboard'));
      this.loading = false;

      if (data?.success) {
        this.store.leaderboard = data.items;
      }
    },
  },
};
</script>
