export default {
  'United States': {
    currency: 'USD',
    icon: '&#36;',
  },
  'United Kingdom': {
    currency: 'GBP',
    icon: '&#163;',
  },
  Canada: {
    currency: 'CAD',
    icon: '&#36;',
  },
  Australia: {
    currency: 'AUD',
    icon: '&#36;',
  },
  France: {
    currency: 'EUR',
    icon: '&#8364;',
  },
  Germany: {
    currency: 'EUR',
    icon: '&#8364;',
  },
  Italy: {
    currency: 'EUR',
    icon: '&#8364;',
  },
  Japan: {
    currency: 'JPY',
    icon: '&#165;',
  },
  Mexico: {
    currency: 'MXN',
    icon: '&#36;',
  },
  Netherlands: {
    currency: 'EUR',
    icon: '&#8364;',
  },
  Spain: {
    currency: 'EUR',
    icon: '&#8364;',
  },
  'United Arab Emirates': {
    currency: 'AED',
    icon: '&#x62f;.&#x625;',
  },
  Brazil: {
    currency: 'BRL',
    icon: '&#82;&#36;',
  },
  Singapore: {
    currency: 'SGD',
    icon: '&#36;',
  },
  Turkey: {
    currency: 'TRY',
    icon: '&#8378;',
  },
  India: {
    currency: 'INR',
    icon: '&#8377;',
  },
};

export function decodeHtmlEntity(str) {
  const txt = document.createElement('textarea');
  txt.innerHTML = str;
  return txt.value;
}
