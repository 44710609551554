<template>
  <div class="card multisteps-form__panel p-3 border-radius-xl bg-white" data-animation="FadeIn">
    <h5 class="font-weight-bolder mb-0">Basic info</h5>
    <p class="mb-0 text-sm">Fields with * are mandatory</p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-sm-12 col-md-6 my-1">
          <label htmlFor="newuser-firstname">First Name *</label>
          <argon-input
            id="newuser-firstname"
            v-model="$parent.user.first_name"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. Michael"
          />
        </div>
        <div class="col-sm-12 col-md-6 my-1">
          <label htmlFor="newuser-lastname">Last Name *</label>
          <argon-input
            id="newuser-lastname"
            v-model="$parent.user.last_name"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. Prior"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-12 my-1">
          <label htmlFor="newuser-email">Email Address *</label>
          <argon-input
            id="newuser-email"
            v-model="$parent.user.email"
            class="multisteps-form__input"
            type="email"
            placeholder="eg. argon@dashboard.com"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 my-1">
          <label htmlFor="newuser-cnic-number">CNIC Number *</label>
          <argon-input
            id="newuser-cnic-number"
            v-model="$parent.user.cnic_number"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. 3520212345673"
          />
        </div>
        <div class="col-sm-12 col-md-3 my-1">
          <label htmlFor="newuser-gender">Gender *</label>
          <argon-select
            id="newuser-gender"
            v-model="$parent.user.gender"
            name="gender"
            :options="[
              {
                value: 'Male',
                label: 'Male',
              },
              {
                value: 'Female',
                label: 'Female',
              },
            ]"
          />
        </div>
        <div class="col-sm-12 col-md-3 my-1">
          <label htmlFor="newuser-role">Role *</label>
          <argon-select
            id="newuser-role"
            v-model="$parent.user.role"
            name="role"
            :options="[
              {
                value: 'Marketer',
                label: 'Marketer (PM)',
              },
              {
                value: 'Manager',
                label: 'Manager (PMM)',
              },
              {
                value: 'Admin',
                label: 'Admin',
              },
            ]"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 my-1" :class="$parent.user.role === 'Manager' ? 'col-md-6' : ''">
          <label htmlFor="newuser-whatsapp-number">Whatsapp Number *</label>
          <argon-input
            id="newuser-whatsapp-number"
            v-model="$parent.user.whatsapp_number"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. +92 123 4567890"
          />
        </div>
        <div v-if="$parent.user.role === 'Manager'" class="col-sm-12 col-md-6 my-1">
          <label htmlFor="newuser-va-number">VA Number</label>
          <argon-input
            id="newuser-va-number"
            v-model="$parent.user.va_number"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. +92 123 4567890"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-6 my-1">
          <label htmlFor="newuser-password">Password *</label>
          <argon-input
            id="newuser-password"
            v-model="$parent.user.password"
            class="multisteps-form__input"
            type="password"
            placeholder="******"
          />
        </div>
        <div class="col-sm-12 col-md-6 my-1">
          <label htmlFor="newuser-confirm-password">Confirm Password *</label>
          <argon-input
            id="newuser-confirm-password"
            v-model="$parent.user.confirmPassword"
            class="multisteps-form__input"
            type="password"
            placeholder="******"
          />
        </div>
      </div>
      <div class="button-row d-flex mt-3">
        <argon-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click="$parent.nextStep"
        >
          Next
        </argon-button>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonInput from '@/components/ArgonInput.vue';
import ArgonButton from '@/components/ArgonButton.vue';
import Choices from 'choices.js';
import ArgonSelect from '@/components/ArgonSelect.vue';

export default {
  name: 'UserInfo',
  components: {
    ArgonSelect,
    ArgonInput,
    ArgonButton,
  },
  mounted() {
    if (document.getElementById('newuser-gender')) {
      const element = document.getElementById('newuser-gender');
      new Choices(element, {
        searchEnabled: false,
        allowHTML: true,
      });
    }
    if (document.getElementById('newuser-role')) {
      const element = document.getElementById('newuser-role');
      new Choices(element, {
        searchEnabled: false,
        allowHTML: true,
      });
    }
  },
};
</script>
