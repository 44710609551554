function appendFormData(formData, key, value, parentKey) {
  if (value instanceof File) {
    formData.append(parentKey ? `${parentKey}[${key}]` : key, value);
  } else if (typeof value === 'object' && value !== null) {
    for (let [nestedKey, nestedValue] of Object.entries(value)) {
      appendFormData(formData, nestedKey, nestedValue, parentKey ? `${parentKey}[${key}]` : key);
    }
  } else {
    formData.append(parentKey ? `${parentKey}[${key}]` : key, value);
  }
}

export default function createFormData(obj) {
  const formData = new FormData();

  for (let [key, value] of Object.entries(obj)) {
    appendFormData(formData, key, value);
  }

  return formData;
}
