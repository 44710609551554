<template>
  <div class="card multisteps-form__panel p-3 border-radius-xl bg-white" data-animation="FadeIn">
    <h5 class="font-weight-bolder mb-0">Bank Account</h5>
    <p class="mb-0 text-sm">Here you have to add user bank account information.</p>

    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 my-1">
          <label htmlFor="newuser-bank-name">Bank Name</label>
          <argon-select
            id="newuser-bank-name"
            v-model="$parent.user.bank.name"
            name="bank"
            :options="banks"
          />
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-sm-12 col-md-6 my-1">
          <label htmlFor="newuser-account-title">Account Title</label>
          <argon-input
            id="newuser-account-title"
            v-model="$parent.user.bank.title"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. Yousuf K. Arain"
          />
        </div>
        <div class="col-sm-12 col-md-6 my-1">
          <label htmlFor="newuser-account-number">Account Number</label>
          <argon-input
            id="newuser-account-number"
            v-model="$parent.user.bank.account"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. 234865238764"
          />
        </div>
      </div>
      <div class="button-row d-flex mt-3">
        <argon-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="$parent.prevStep"
        >
          Prev
        </argon-button>
        <argon-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto"
          :is-disabled="$parent.loading"
          @click="$parent.handleSubmit"
        >
          <i v-if="$parent.loading" class="fa fa-refresh fa-spin" />
          <span v-else>Create</span>
        </argon-button>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonInput from '@/components/ArgonInput.vue';
import ArgonButton from '@/components/ArgonButton.vue';
import Choices from 'choices.js';
import ArgonSelect from '@/components/ArgonSelect.vue';
import banks from '@/utils/banks';

export default {
  name: 'UserBank',
  data() {
    return {
      banks,
    };
  },
  components: {
    ArgonSelect,
    ArgonInput,
    ArgonButton,
  },
  mounted() {
    if (document.getElementById('newuser-bank-name')) {
      var element = document.getElementById('newuser-bank-name');
      new Choices(element, {
        searchEnabled: true,
        allowHTML: true,
      });
    }
  },
};
</script>
