<template>
  <div class="card">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between">
        <h6 class="mb-0">Completed Orders</h6>
        <button
          type="button"
          class="mb-0 btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm d-flex align-items-center justify-content-center"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title
          data-bs-original-title="Completed orders in last 2 months"
        >
          <i class="fas fa-info" aria-hidden="true"></i>
        </button>
      </div>
      <div class="d-flex align-items-center">
        <span class="badge badge-md badge-dot me-4">
          <i class="bg-success"></i>
          <span class="text-xs text-dark">Current Month</span>
        </span>
        <span class="badge badge-md badge-dot me-4">
          <i class="bg-dark"></i>
          <span class="text-xs text-dark">Last Month</span>
        </span>
      </div>
    </div>
    <div class="p-3 card-body">
      <div class="chart">
        <canvas :id="chartId" class="chart-canvas" height="300"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
  name: 'LineChartCard',
  props: {
    dataset: {
      type: Object,
      required: true,
      currentMonth: Array,
      lastMonth: Array,
    },
  },
  data() {
    return {
      chartId: 'chart-line',
      myChart: null,
    };
  },
  mounted() {
    this.$watch('dataset', () => {
      this.createChart();
    });
  },
  methods: {
    async createChart() {
      if (this.myChart) {
        this.myChart.destroy();
      }

      const lineChart = document.getElementById(this.chartId).getContext('2d');
      const gradientStroke1 = lineChart.createLinearGradient(0, 230, 0, 50);

      gradientStroke1.addColorStop(1, 'rgba(94, 114, 228,0.2)');
      gradientStroke1.addColorStop(0.2, 'rgba(72,72,176,0.0)');
      gradientStroke1.addColorStop(0, 'rgba(203,12,159,0)'); //purple colors

      const gradientStroke2 = lineChart.createLinearGradient(0, 230, 0, 50);

      gradientStroke2.addColorStop(1, 'rgba(20,23,39,0.2)');
      gradientStroke2.addColorStop(0.2, 'rgba(72,72,176,0.0)');
      gradientStroke2.addColorStop(0, 'rgba(20,23,39,0)'); //purple colors
      // Line chart

      let chartStatus = Chart.getChart(this.id);
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      this.myChart = new Chart(lineChart, {
        type: 'line',
        data: {
          labels: Array.from({
            length: Math.max(this.dataset.currentMonth.length, this.dataset.lastMonth.length),
          }).map((_, i) => i + 1),
          datasets: [
            {
              label: 'Current Month',
              tension: 0.4,
              pointRadius: 2,
              pointBackgroundColor: '#4BB543 ',
              borderColor: '#4BB543 ',
              // eslint-disable-next-line no-dupe-keys
              borderWidth: 3,
              backgroundColor: gradientStroke1,
              fill: true,
              data: this.dataset.currentMonth,
              maxBarThickness: 6,
            },
            {
              label: 'Last Month',
              tension: 0.4,
              pointRadius: 2,
              pointBackgroundColor: '#3A416F',
              borderColor: '#3A416F',
              // eslint-disable-next-line no-dupe-keys
              borderWidth: 3,
              backgroundColor: gradientStroke2,
              fill: true,
              data: this.dataset.lastMonth,
              maxBarThickness: 6,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: 'index',
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                padding: 10,
                color: '#9ca2b7',
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: true,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                color: '#9ca2b7',
                padding: 10,
              },
            },
          },
        },
      });
    },
  },
};
</script>
