<template>
  <div class="my-2 card">
    <div class="p-3 card-body">
      <div class="d-flex" :class="rowReverse ? '' : 'flex-row-reverse justify-content-between'">
        <div
          class="text-center shadow icon icon-shape"
          :class="[
            typeof icon === 'object' ? `${icon.background} ${icon.shape}` : 'border-radius-md',
            rowReverse ? 'me-2' : '',
          ]"
        >
          <i
            class="text-lg opacity-10"
            :class="typeof icon === 'string' ? icon : icon.component"
            aria-hidden="true"
          ></i>
        </div>
        <div :class="classContent">
          <div class="numbers">
            <p class="mb-0 text-sm text-uppercase font-weight-bold">
              {{ title }}
            </p>
            <h5 class="mb-0 font-weight-bolder">
              {{
                card.totalCommission?.toLocaleString('en-US', {
                  style: 'currency',
                  currency: 'PKR',
                  minimumFractionDigits: 2,
                })
              }}
            </h5>
            <span
              v-if="!hidePercentage"
              :class="`text-${getColor(card.percentageChange)}`"
              class="text-sm font-weight-bolder text-secondary"
            >
              {{ getValue(parseInt(card.percentageChange)) }}
              <span class="font-weight-normal text-secondary">since last month</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DefaultStatisticsCard',
  props: {
    card: {
      type: Object,
      required: true,
      status: String,
      totalCommission: Number,
      percentageChange: Number,
    },
    invert: {
      type: Boolean,
      default: false,
    },
    rowReverse: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    hidePercentage: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: [String, Object],
      component: {
        type: String,
      },
      background: {
        type: String,
      },
      shape: {
        type: String,
      },
      default: () => ({
        background: 'bg-white',
        shape: ' border-radius-md',
      }),
    },
    classContent: {
      type: String,
      default: '',
    },
  },
  methods: {
    getColor(value) {
      if (value > 0) {
        if (this.invert) {
          return 'danger';
        } else {
          return 'success';
        }
      } else if (value < 0) {
        if (this.invert) {
          return 'success';
        } else {
          return 'danger';
        }
      } else {
        return 'secondary';
      }
    },
    getValue(value) {
      if (value > 0) {
        return value > 1000 ? '+999%' : `+${value}%`;
      } else if (value < 0) {
        return `${value}%`;
      } else {
        return `${value}%`;
      }
    },
  },
};
</script>
