<template>
  <div id="sidenav-collapse-main" class="collapse navbar-collapse w-auto h-auto h-100">
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="dashboard"
          nav-text="Dashboard"
          :class="getRoute() === 'dashboard' ? 'active' : ''"
          :collapse="false"
          @click="$router.push('/dashboard')"
        >
          <template #icon>
            <i class="ni ni-shop text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="leaderboard"
          nav-text="Leaderboard"
          :class="getRoute() === 'leaderboard' ? 'active' : ''"
          :collapse="false"
          @click="$router.push('/leaderboard')"
        >
          <template #icon>
            <i class="ni ni-trophy text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-collapse>
      </li>
      <li class="mt-3 nav-item">
        <h6
          v-show="store.isPinned"
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6 ms-2"
        >
          OPERATIONS
        </h6>
      </li>
      <li v-if="store.role === 'Marketer'" class="nav-item">
        <sidenav-collapse
          collapse-ref="products"
          nav-text="Products"
          :class="getRoute() === 'products' ? 'active' : ''"
          :collapse="false"
          @click="$router.push('/products')"
        >
          <template #icon>
            <i class="ni ni-archive-2 text-warning text-sm opacity-10"></i>
          </template>
        </sidenav-collapse>
      </li>
      <li v-else class="nav-item">
        <sidenav-collapse
          collapse-ref="products"
          nav-text="Products"
          :class="getRoute() === 'products' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-archive-2 text-warning text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item
                v-if="store.user?.status !== 'Restricted'"
                to="/products/add"
                mini-icon="A"
                text="Add Product"
              />
              <sidenav-item to="/products" mini-icon="P" text="Products Listing" />
              <sidenav-item to="/products/archived" mini-icon="P" text="Archived Products" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="reservations"
          nav-text="Reservations"
          :class="getRoute() === 'reservations' ? 'active' : ''"
          :collapse="false"
          @click="$router.push('/reservations')"
        >
          <template #icon>
            <i class="ni ni-calendar-grid-58 text-info text-sm opacity-10"></i>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="orders"
          nav-text="Orders"
          :class="getRoute() === 'orders' ? 'active' : ''"
          :collapse="false"
          @click="$router.push('/orders')"
        >
          <template #icon>
            <i class="ni ni-bag-17 text-success text-sm opacity-10"></i>
          </template>
        </sidenav-collapse>
      </li>

      <li v-if="store.role === 'Admin'" class="mt-3 nav-item">
        <hr class="mt-0 horizontal dark" />
        <h6
          v-show="store.isPinned"
          class="text-xs ps-4 ms-2 text-uppercase font-weight-bolder opacity-6 ms-2"
        >
          ADMINISTRATE
        </h6>
      </li>

      <li v-if="store.role === 'Admin'" class="nav-item">
        <sidenav-collapse
          collapse-ref="users"
          nav-text="Users"
          :class="getRoute() === 'users' ? 'active' : ''"
        >
          <template #icon>
            <i class="ni ni-circle-08 text-warning text-sm opacity-10"></i>
          </template>
          <template #list>
            <ul class="nav ms-4">
              <!-- nav links -->
              <sidenav-item to="/users/add" mini-icon="A" text="Add User" />
              <sidenav-item to="/users" mini-icon="U" text="Active Users" />
              <sidenav-item to="/users/inactive" mini-icon="I" text="InActive Users" />
              <sidenav-item to="/users/terminated" mini-icon="T" text="Terminated Users" />
              <sidenav-item to="/users/restricted" mini-icon="R" text="Restricted Users" />
              <sidenav-item to="/users/pending" mini-icon="P" text="Pending Users" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <li v-if="store.role === 'Admin'" class="nav-item">
        <sidenav-collapse
          collapse-ref="bonus-payments"
          nav-text="Bonuses"
          :class="getRoute() === 'bonus-payments' ? 'active' : ''"
          :collapse="false"
          @click="$router.push('/bonus-payments')"
        >
          <template #icon>
            <i class="ni ni-credit-card text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-collapse>
      </li>

      <li class="mt-3 nav-item">
        <hr class="mt-0 horizontal dark" />
        <h6
          v-show="store.isPinned"
          class="text-xs ps-4 ms-2 text-uppercase font-weight-bolder opacity-6 ms-2"
        >
          OTHERS
        </h6>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="reports"
          nav-text="Reports"
          :class="getRoute() === 'reports' ? 'active' : ''"
          :collapse="false"
          @click="$router.push('/reports')"
        >
          <template #icon>
            <i
              class="ni ni-collection text-sm"
              :class="
                store.sidebarType === 'bg-default' || store.darkMode ? 'text-white' : 'text-dark'
              "
            ></i>
          </template>
        </sidenav-collapse>
      </li>

      <li class="nav-item">
        <sidenav-collapse
          collapse-ref="blacklist"
          nav-text="Blacklist"
          :class="getRoute() === 'blacklist' ? 'active' : ''"
          :collapse="false"
          @click="$router.push('/blacklist')"
        >
          <template #icon>
            <i
              class="ni ni-lock-circle-open text-sm"
              :class="
                store.sidebarType === 'bg-default' || store.darkMode ? 'text-white' : 'text-dark'
              "
            ></i>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
  <div v-if="store.role !== 'Admin'" class="mt-3 sidenav-footer">
    <sidenav-card
      :card="{
        title: 'Need Help?',
        description: 'Please contact us',
        links: [
          {
            label: 'Whatsapp',
            route: `https://wa.me/${helpContact}`,
            color: 'primary',
          },
        ],
      }"
    />
  </div>
</template>
<script>
import SidenavItem from './SidenavItem.vue';
import SidenavCollapse from './SidenavCollapse.vue';
import SidenavCard from './SidenavCard.vue';

export default {
  name: 'SidenavList',
  components: {
    SidenavItem,
    SidenavCollapse,
    SidenavCard,
  },
  inject: ['store'],
  data() {
    return {
      helpContact: process.env.VUE_APP_HELP_CONTACT,
    };
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split('/');
      return routeArr[1];
    },
  },
};
</script>
