<template>
  <div id="welcome-loading-screen">
    <div id="welcome-durood-container">
      <div>
        <img :src="bismillah" style="object-fit: contain" />
      </div>
      <div>
        <img :src="durood" style="object-fit: contain" />
      </div>
    </div>
    <div id="welcome-loading-logo-container">
      <img :src="logo" style="object-fit: contain" />
    </div>
  </div>
</template>

<script>
import bismillah from '@/assets/img/welcome-screen/bismillah.png';
import durood from '@/assets/img/welcome-screen/durood.png';
import logo from '@/assets/img/welcome-screen/logo.png';

export default {
  name: 'Welcome',
  data() {
    return {
      bismillah,
      durood,
      logo,
    };
  },
};
</script>

<style>
#welcome-loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 9999;
}

#welcome-durood-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
  animation: fadeIn 1s;
}

#welcome-durood-container > div {
  width: 100%;
  text-align: center;
}

#welcome-durood-container > div:first-child > img {
  height: 75px;
  width: 300px;
  object-fit: contain;
}

#welcome-durood-container > div:last-child > img {
  height: 44px;
  width: 300px;
  object-fit: contain;
}

#welcome-loading-logo-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#welcome-loading-logo-container > img {
  height: 154px;
  width: 150px;
  object-fit: contain;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  #welcome-loading-logo-container > img {
    animation: Hero-logo-spin infinite 5s linear;
  }
}

@keyframes Hero-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 500px) {
  #welcome-durood-container {
    margin-top: 20%;
  }
}
</style>
