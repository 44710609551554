const countries = {
  'United States': {
    short: '🇺🇸 US',
    full: '🇺🇸 United States',
  },
  'United Kingdom': {
    short: '🇬🇧 UK',
    full: '🇬🇧 United Kingdom',
  },
  Canada: {
    short: '🇨🇦 CA',
    full: '🇨🇦 Canada',
  },
  Australia: {
    short: '🇦🇺 AU',
    full: '🇦🇺 Australia',
  },
  France: {
    short: '🇫🇷 FR',
    full: '🇫🇷 France',
  },
  Germany: {
    short: '🇩🇪 DE',
    full: '🇩🇪 Germany',
  },
  Italy: {
    short: '🇮🇹 IT',
    full: '🇮🇹 Italy',
  },
  Japan: {
    short: '🇯🇵 JP',
    full: '🇯🇵 Japan',
  },
  Mexico: {
    short: '🇲🇽 MX',
    full: '🇲🇽 Mexico',
  },
  Netherlands: {
    short: '🇳🇱 NL',
    full: '🇳🇱 Netherlands',
  },
  Spain: {
    short: '🇪🇸 ES',
    full: '🇪🇸 Spain',
  },
  'United Arab Emirates': {
    short: '🇦🇪 UAE',
    full: '🇦🇪 United Arab Emirates',
  },
  Brazil: {
    short: '🇧🇷 BR',
    full: '🇧🇷 Brazil',
  },
  Singapore: {
    short: '🇸🇬 SG',
    full: '🇸🇬 Singapore',
  },
  Turkey: {
    short: '🇹🇷 TR',
    full: '🇹🇷 Turkey',
  },
  India: {
    short: '🇮🇳 IN',
    full: '🇮🇳 India',
  },
};

export const countryDropdown = [
  {
    value: '',
    label: 'Country',
  },
  {
    value: 'United States',
    label: 'United States',
  },
  {
    value: 'India',
    label: 'India',
  },
  {
    value: 'Indonesia',
    label: 'Indonesia',
  },
  {
    value: 'Pakistan',
    label: 'Pakistan',
  },
  {
    value: 'Brazil',
    label: 'Brazil',
  },
  {
    value: 'Nigeria',
    label: 'Nigeria',
  },
  {
    value: 'Bangladesh',
    label: 'Bangladesh',
  },
  {
    value: 'Russia',
    label: 'Russia',
  },
  {
    value: 'Mexico',
    label: 'Mexico',
  },
  {
    value: 'Japan',
    label: 'Japan',
  },
  {
    value: 'Ethiopia',
    label: 'Ethiopia',
  },
  {
    value: 'Philippines',
    label: 'Philippines',
  },
  {
    value: 'Egypt',
    label: 'Egypt',
  },
  {
    value: 'Vietnam',
    label: 'Vietnam',
  },
  {
    value: 'DR Congo',
    label: 'DR Congo',
  },
  {
    value: 'Turkey',
    label: 'Turkey',
  },
  {
    value: 'Iran',
    label: 'Iran',
  },
  {
    value: 'Germany',
    label: 'Germany',
  },
  {
    value: 'Thailand',
    label: 'Thailand',
  },
  {
    value: 'United Kingdom',
    label: 'United Kingdom',
  },
  {
    value: 'France',
    label: 'France',
  },
  {
    value: 'Italy',
    label: 'Italy',
  },
  {
    value: 'South Africa',
    label: 'South Africa',
  },
  {
    value: 'South Korea',
    label: 'South Korea',
  },
  {
    value: 'Colombia',
    label: 'Colombia',
  },
  {
    value: 'Spain',
    label: 'Spain',
  },
  {
    value: 'Ukraine',
    label: 'Ukraine',
  },
  {
    value: 'Argentina',
    label: 'Argentina',
  },
  {
    value: 'Poland',
    label: 'Poland',
  },
  {
    value: 'Canada',
    label: 'Canada',
  },
  {
    value: 'Saudi Arabia',
    label: 'Saudi Arabia',
  },
  {
    value: 'Malaysia',
    label: 'Malaysia',
  },
  {
    value: 'Australia',
    label: 'Australia',
  },
];

function country(name, opt) {
  return opt?.short ? countries[name].short : countries[name].full;
}

export default country;
