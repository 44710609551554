<template>
  <div class="py-4 container-fluid">
    <div v-if="store.role !== 'Marketer'" class="row">
      <div class="col-lg-6 col-md-6 col-12">
        <default-statistics-card
          title="Total Payable Commission"
          value="Rs 0"
          :card="store.cards[0]"
          :icon="{
            component: 'ni ni-money-coins',
            background: 'bg-gradient-warning',
            shape: 'rounded-circle',
          }"
        />
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <default-statistics-card
          title="Total Paid Commission"
          :card="store.cards[2]"
          :icon="{
            component: 'ni ni-credit-card',
            background: 'bg-gradient-success',
            shape: 'rounded-circle',
          }"
        />
      </div>
    </div>
    <div v-else-if="store.role === 'Marketer'" class="row">
      <div class="col-lg-6 col-md-6 col-12">
        <default-statistics-card
          title="Month's Earning"
          :card="store.cards[0]"
          :icon="{
            component: 'ni ni-money-coins',
            background: 'bg-gradient-warning',
            shape: 'rounded-circle',
          }"
        />
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <default-statistics-card
          title="Month's Receivable"
          :card="store.cards[1]"
          :icon="{
            component: 'ni ni-credit-card',
            background: 'bg-gradient-primary',
            shape: 'rounded-circle',
          }"
        />
      </div>
    </div>
    <div class="row">
      <mini-statistics-card :card="store.cards[3]" />
      <mini-statistics-card :card="store.cards[4]" />
      <mini-statistics-card :card="store.cards[5]" />
      <mini-statistics-card :card="store.cards[6]" />
    </div>
    <div class="row">
      <mini-statistics-card :card="store.cards[7]" />
      <mini-statistics-card :card="store.cards[8]" />
      <mini-statistics-card :card="store.cards[9]" />
      <mini-statistics-card :card="store.cards[10]" />
    </div>
    <div class="row">
      <mini-statistics-card :card="store.cards[11]" />
      <mini-statistics-card :card="store.cards[12]" />
      <mini-statistics-card :card="store.cards[13]" />
      <mini-statistics-card :card="store.cards[14]" />
    </div>
    <div class="row">
      <mini-statistics-card :card="store.cards[15]" />
      <mini-statistics-card :card="store.cards[16]" />
      <mini-statistics-card :card="store.cards[17]" />
      <mini-statistics-card :card="store.cards[18]" />
    </div>
    <div v-if="store.role === 'Admin'" class="row">
      <div class="col-lg-6 col-md-6 col-12">
        <default-statistics-card
          title="Receivable Commission"
          :card="store.cards[19]"
          :hide-percentage="true"
          :icon="{
            component: 'ni ni-money-coins',
            background: 'bg-gradient-warning',
            shape: 'rounded-circle',
          }"
        />
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <default-statistics-card
          title="Received Commission"
          :card="store.cards[20]"
          :hide-percentage="true"
          :icon="{
            component: 'ni ni-credit-card',
            background: 'bg-gradient-success',
            shape: 'rounded-circle',
          }"
        />
      </div>
    </div>
    <div v-else-if="store.role === 'Marketer'" class="row">
      <div class="col-lg-6 col-md-6 col-12">
        <default-statistics-card
          title="Month's Earned Bonus"
          :card="{ totalCommission: store.user.other_data?.earned_bonus || 0 }"
          :hide-percentage="true"
          :icon="{
            component: 'ni ni-trophy',
            background: 'bg-gradient-info',
            shape: 'rounded-circle',
          }"
        />
      </div>
      <div class="col-lg-6 col-md-6 col-12">
        <default-statistics-card
          title="Month's Receivable Bonus"
          :card="{ totalCommission: store.user.other_data?.receivable_bonus || 0 }"
          :hide-percentage="true"
          :icon="{
            component: 'ni ni-paper-diploma',
            background: 'bg-gradient-success',
            shape: 'rounded-circle',
          }"
        />
      </div>
    </div>
    <div class="mt-2 row">
      <div class="col-lg-4 col-sm-6">
        <pie-chart-card />
      </div>
      <div class="mt-4 col-lg-8 col-sm-6 mt-sm-0">
        <line-chart-card :dataset="store.lineChart" />
      </div>
    </div>
    <div class="mt-4 row">
      <div class="col-lg-12">
        <activity-card
          title="Recent Activity"
          :headers="['Product', 'AMZ Order', 'Market', 'Date/Time', 'Status']"
          :lists="store.activity"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultStatisticsCard from './components/DefaultStatisticsCard.vue';
import PieChartCard from './components/PieChartCard.vue';
import LineChartCard from './components/LineChartCard.vue';
import ActivityCard from './components/ActivityCard.vue';
import MiniStatisticsCard from './components/MiniStatisticsCard.vue';

// images
import setTooltip from '@/assets/js/tooltip.js';
import to from 'await-to-js';

export default {
  name: 'Dashboard',
  components: {
    ActivityCard,
    MiniStatisticsCard,
    PieChartCard,
    LineChartCard,
    DefaultStatisticsCard,
  },
  inject: ['store', 'actions'],
  mounted() {
    this.fetchCards();
    this.fetchLineChart();
    this.fetchActivity();
    setTooltip(this.store.bootstrap);
    this.store.showWelcome = false;
  },
  methods: {
    async fetchCards() {
      const [, data] = await to(this.actions.api('get', '/stats/cards'));

      if (data?.success) {
        this.store.cards = this.mergeArraysOnName(this.store.cards, data.cards);
      }
    },
    async fetchLineChart() {
      const [, data] = await to(this.actions.api('get', '/stats/line-chart'));

      if (data?.success) {
        this.store.lineChart = data.lineChart;
      }
    },
    async fetchActivity() {
      const [, data] = await to(this.actions.api('get', '/stats/activity'));

      if (data?.success) {
        this.store.activity = data.activity;
      }
    },

    mergeArraysOnName(array1, array2) {
      // Create a map from the second array for quick lookup by name
      const map2 = new Map(array2.map((item) => [item.name, item]));

      // Merge the arrays
      const mergedArray = array1.map((item) => {
        if (map2.has(item.name)) {
          // If the same name exists in array2, merge the objects
          // This uses the spread operator to combine properties, with array2's properties taking precedence
          return { ...item, ...map2.get(item.name) };
        } else {
          // If the name doesn't exist in array2, return the item from array1 as is
          return item;
        }
      });

      // Optionally, add items from array2 that were not in array1
      array2.forEach((item) => {
        if (!mergedArray.some((mergedItem) => mergedItem.name === item.name)) {
          mergedArray.push(item);
        }
      });

      return mergedArray;
    },
  },
};
</script>
