const statusRights = {
  Marketer: ['Ordered', 'Reviewed', 'Feedbacked', 'Refund Delayed', 'Feedback Sent', 'Cancelled'],
  Manager: [
    'Ordered',
    'Corrected',
    'On-Hold',
    'Review Deleted',
    'Sent to Seller',
    'Feedback Rfnd',
    'Review Awaited',
    'Refunded',
    'Cancelled',
  ],
  Admin: [
    'Ordered',
    'Corrected',
    'On-Hold',
    'Refund Delayed',
    'Review Deleted',
    'Sent to Seller',
    'Feedback Rfnd',
    'Review Awaited',
    'Refunded',
    'Cancelled',
    'Comsn Processing',
    'Completed',
  ],
};

export default statusRights;
