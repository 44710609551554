<template>
  <div class="py-4 px-1 px-sm-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="row">
              <div class="col-sm-4 col-xxl-6">
                <h5 class="mb-0 text-center text-sm-start">{{ $route.name }}</h5>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table id="users-list" class="table align-items-center mb-0">
                <thead class="thead-light">
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      ID
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Name
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Email
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Bank Name
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Bank Account
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Earned Bonus
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Payable Bonus
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-show="loading && !store.bonuses.length">
                    <td colspan="8">
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr v-show="!loading && !store.bonuses.length">
                    <td colspan="8">
                      <div class="d-flex justify-content-center">
                        <p class="text-monospace">No data found!</p>
                      </div>
                    </td>
                  </tr>

                  <tr v-for="user in store.bonuses" :key="user._id">
                    <td class="text-sm ps-4 py-3">{{ user.user_id }}</td>

                    <td>
                      <div class="d-flex">
                        <argon-avatar
                          :img="
                            user.profile_pic
                              ? `${mediaUrl}${user.profile_pic}`
                              : user.gender === 'Male'
                              ? malePlaceholder
                              : femalePlaceholder
                          "
                          size="sm"
                          alt="avatar image"
                          border-radius="lg"
                        />
                        <h6 class="my-auto ms-3">
                          {{ actions.shortenString(`${user.first_name} ${user.last_name}`) }}
                        </h6>
                      </div>
                    </td>
                    <td class="text-sm" :title="user.email">
                      {{ actions.shortenString(user.email) }}
                    </td>
                    <td class="text-sm">{{ user.bank?.name || 'N/A' }}</td>
                    <td class="text-sm">
                      {{ user.bank?.account || 'N/A' }}
                    </td>
                    <td class="text-sm">
                      {{
                        user.other_data?.earned_bonus?.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'PKR',
                          minimumFractionDigits: 2,
                        })
                      }}
                    </td>
                    <td class="text-sm">
                      {{
                        user.other_data?.receivable_bonus?.toLocaleString('en-US', {
                          style: 'currency',
                          currency: 'PKR',
                          minimumFractionDigits: 2,
                        })
                      }}
                    </td>
                    <td class="text-sm">
                      <router-link :to="`/users/${user.user_id}`" title="View User">
                        <i class="fas fa-eye text-secondary"></i>
                      </router-link>

                      <span title="Mark as paid" @click="markAsPaid(user._id)">
                        <i class="fa fa-check-circle text-secondary mx-3 cursor-pointer" />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination
              :total-count="totalItems"
              :current-page="currentPage"
              :on-page-change="pageChange"
              :page-size="20"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Libs
import to from 'await-to-js';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';

// Components
import ArgonAvatar from '@/components/ArgonAvatar.vue';
import Pagination from '@/components/Pagination.vue';
import router from '@/router';

// Assets
import malePlaceholder from '@/assets/img/male.webp';
import femalePlaceholder from '@/assets/img/female.webp';

const toast = useToast();

export default {
  name: 'BonusPayments',
  components: { Pagination, ArgonAvatar },
  inject: ['store', 'actions'],
  data() {
    const { query } = useRoute();
    return {
      mediaUrl: process.env.VUE_APP_MEDIA_URL,
      malePlaceholder,
      femalePlaceholder,
      loading: false,
      currentPage: parseInt(query.page) || 1,
      totalItems: 0,
      showFilters: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData(page = this.currentPage) {
      this.loading = true;
      const [, data] = await to(this.actions.api('get', `/users/bonus-eligible/?page=${page}`));

      this.loading = false;

      if (data?.success) {
        this.store.bonuses = data.users;
      }

      this.totalItems = data.totalItems || 0;
    },
    pageChange(page) {
      this.store.bonuses = [];
      this.currentPage = page;
      router.push(`${window.location.pathname}?page=${page}`);
      this.fetchData(page);
    },
    async markAsPaid(id) {
      const [err] = await to(this.actions.api('patch', `/users/bonus-paid/${id}`));

      if (!err) {
        toast.success('Bonus has been marked as paid');
        this.fetchData();
      }
    },
  },
};
</script>
