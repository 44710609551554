<template>
  <div class="policies p-2 my-1 d-flex justify-content-between align-items-center">
    <div>
      <p class="text-xs m-0">{{ value }}</p>
    </div>
    <button
      v-if="!hideCross"
      type="button"
      class="btn-close d-flex justify-content-center align-items-center"
      @click="$parent.removeRule(name, idx)"
    >
      <span aria-hidden="true" class="text-lg font-weight-bold">&times;</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'RuleCard',
  props: {
    idx: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    hideCross: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
.policies {
  background-color: #c2e9fd;
  border: 1px solid #00a7ff;
  border-radius: 5px;
}
</style>
