export default function removeEmptyFields(obj) {
  // Iterate over object properties
  Object.keys(obj).forEach((key) => {
    // If the property is an object, recursively remove empty fields
    if (
      typeof obj[key] === 'object' &&
      obj[key] !== null &&
      !Array.isArray(obj[key]) &&
      !(obj[key] instanceof File)
    ) {
      removeEmptyFields(obj[key]);
      // If the object becomes empty after removal of fields, delete the object
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    } else if (
      !(obj[key] instanceof File) &&
      (obj[key] === '' || obj[key] === null || (Array.isArray(obj[key]) && obj[key].length === 0))
    ) {
      // If the property is an empty string, null, or an empty array, delete it
      delete obj[key];
    }
  });

  return obj;
}
