<template>
  <div class="container-fluid mt-3 mt-lg-6 px-2 px-sm-4">
    <div class="row mb-5">
      <div class="col-lg-3">
        <side-nav />
      </div>
      <div class="col-lg-9 mt-0">
        <side-nav-item />
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from './components/SideNav.vue';
import SideNavItem from './components/SideNavItem.vue';

export default {
  name: 'ViewUser',
  components: {
    SideNav,
    SideNavItem,
  },
};
</script>
