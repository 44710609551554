<template>
  <div class="row p-4">
    <div class="col-md-6 text-center text-sm-start">
      <p class="text-sm m-0" style="line-height: 36px">
        Showing {{ totalCount > pageSize ? pageSize : totalCount }} of {{ totalCount }} entries
      </p>
    </div>
    <div v-if="paginationRange.length > 1 && currentPage !== 0" class="col-md-6">
      <nav aria-label="Page navigation example ">
        <ul class="pagination justify-content-center justify-content-sm-end mb-0">
          <li v-show="currentPage !== 1" class="page-item" @click.prevent="onPrevious">
            <a href="#" class="page-link">
              <i class="fas fa-angle-left"></i>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li
            v-for="pageNumber in paginationRange"
            :key="pageNumber"
            :class="[
              'page-item',
              { selected: pageNumber == currentPage, disabled: pageNumber === '...' },
            ]"
          >
            <a href="#" class="page-link" @click.prevent="() => onPageChange(pageNumber)">
              {{ pageNumber }}
            </a>
          </li>
          <li v-show="lastPage !== currentPage" class="page-item" @click.prevent="onNext">
            <a href="#" class="page-link">
              <i class="fas fa-angle-right"></i>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import { usePagination } from '@/utils/usePagination';

export default {
  name: 'Pagination',
  props: {
    onPageChange: {
      type: Function,
      required: true,
    },
    totalCount: {
      type: Number,
      required: true,
    },
    siblingCount: {
      type: Number,
      default: 1,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      lastPage: '',
      paginationRange: [],
    };
  },
  watch: {
    totalCount: {
      handler() {
        this.init();
      },
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    onPrevious() {
      this.onPageChange(this.currentPage - 1);
    },

    onNext() {
      this.onPageChange(this.currentPage + 1);
    },

    init() {
      this.paginationRange = usePagination({
        currentPage: this.currentPage,
        totalCount: this.totalCount,
        siblingCount: this.siblingCount,
        pageSize: this.pageSize,
      });

      this.lastPage = this.paginationRange[this.paginationRange.length - 1];
    },
  },
};
</script>

<style>
.pagination .selected a {
  background: linear-gradient(310deg, #5e72e4 0%, #825ee4 100%);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  color: #fff;
  border: none;
  border-radius: 50% !important;
}
</style>
