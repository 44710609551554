import { store } from '.';
import axios from 'axios';
import { useToast } from 'vue-toastification';
const toast = useToast();

class Actions {
  toggleConfigurator() {
    store.showConfig = !store.showConfig;
  }

  isRestricted(user) {
    const superAdmins = ['yousufkalim@outlook.com', 'adnansp480@gmail.com'];
    return store.user._id === user._id || superAdmins.includes(user.email);
  }

  shortenString(str, length = window.innerWidth < 1600 ? 22 : 35) {
    if (!str) {
      return '';
    }
    return str.length > length ? `${str.substring(0, length)}...` : str;
  }

  navbarMinimize() {
    const sidenav_show = document.querySelector('#app');

    if (
      sidenav_show.classList.contains('g-sidenav-show') &&
      sidenav_show.classList.contains('g-sidenav-hidden')
    ) {
      sidenav_show.classList.add('g-sidenav-pinned');
      sidenav_show.classList.remove('g-sidenav-hidden');
      store.isPinned = true;
    } else if (
      sidenav_show.classList.contains('g-sidenav-show') &&
      sidenav_show.classList.contains('g-sidenav-pinned')
    ) {
      sidenav_show.classList.add('g-sidenav-hidden');
      sidenav_show.classList.remove('g-sidenav-pinned');
      store.isPinned = false;
    } else {
      sidenav_show.classList.add('g-sidenav-hidden');
      store.isPinned = false;
    }
  }

  setSidebarType(payload) {
    store.sidebarType = payload;
  }

  navbarFixed() {
    store.isNavFixed = !store.isNavFixed;
  }

  toggleDefaultLayout() {
    store.showNavbar = !store.showNavbar;
    store.showSidenav = !store.showSidenav;
    store.showFooter = !store.showFooter;
  }

  toggleSidebarColor(payload) {
    store.sidebarType = payload;
  }

  async api(method, uri, body) {
    // API Call
    const url = process.env.VUE_APP_API_URL + uri;

    const token = localStorage.getItem('token');
    if (token && uri !== '/auth/login') {
      axios.defaults.headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    return new Promise((resolve, reject) => {
      axios[method](url, body)
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err?.response?.status === 401) {
            localStorage.removeItem('token');
            window.location = '/auth/login';
          } else {
            toast.error(err?.response?.data ? err.response.data.message : err?.message);
            reject(err);
          }
        });
    });
  }
}

export default new Actions();
