<template>
  <div class="py-4 container-fluid">
    <div class="row">
      <div class="mx-auto col-lg-9 col-12">
        <div v-if="store.role !== 'Marketer'" class="mt-4 card card-body">
          <h6 class="mb-0">Products</h6>
          <p class="mb-0 text-sm">Export products into a CSV file</p>
          <hr class="my-3 horizontal dark" />
          <div class="row mt-3">
            <div class="col-md-6">
              <label class="form-label" htmlFor="reports-products-start">From *</label>
              <argon-input id="reports-products-start" v-model="product.from" type="date" />
            </div>
            <div class="col-md-6">
              <label class="form-label" htmlFor="reports-products-end">To *</label>
              <argon-input id="reports-products-end" v-model="product.to" type="date" />
            </div>
          </div>
          <div class="row mt-3">
            <div v-show="store.role === 'Admin'" class="col-md-6">
              <label class="form-label" htmlFor="reports-products-user">User ID (Optional)</label>
              <argon-input
                id="reports-products-user"
                v-model="product.manager_uid"
                type="text"
                placeholder="E.g 4599"
              />
            </div>
            <div :class="store.role === 'Manager' ? 'col-12' : 'col-md-6'">
              <label class="form-label" htmlFor="reports-products-status">Status *</label>
              <argon-select
                id="reports-products-status"
                v-model="product.status"
                name="status"
                :options="[
                  {
                    value: 'Active',
                    label: 'Active',
                  },
                  {
                    value: 'Archived',
                    label: 'Archived',
                  },
                  {
                    value: 'Sales End',
                    label: 'Sales End',
                  },
                ]"
              />
            </div>
          </div>

          <!--    Submit/Cancel      -->
          <div class="mt-4 d-flex justify-content-end">
            <button
              type="button"
              name="button"
              class="m-0 btn bg-gradient-primary ms-2"
              :disabled="productsLoading"
              @click="generateProductsReport"
            >
              <i v-if="productsLoading" class="fa fa-refresh fa-spin" />
              <span v-else>Generate</span>
            </button>
          </div>
        </div>
        <div class="mt-4 card card-body">
          <h6 class="mb-0">Orders</h6>
          <p class="mb-0 text-sm">Export orders into a CSV file</p>
          <hr class="my-3 horizontal dark" />
          <div class="row mt-3">
            <div class="col-md-6">
              <label class="form-label" htmlFor="reports-orders-start">From *</label>
              <argon-input id="reports-orders-start" v-model="order.from" type="date" />
            </div>
            <div class="col-md-6">
              <label class="form-label" htmlFor="reports-orders-end">To *</label>
              <argon-input id="reports-orders-end" v-model="order.to" type="date" />
            </div>
          </div>
          <div class="row mt-3">
            <div v-show="store.role !== 'Marketer'" class="col-md-6">
              <label class="form-label" htmlFor="reports-orders-user">User ID (Optional)</label>
              <argon-input
                id="reports-orders-user"
                v-model="order.user_id"
                type="text"
                placeholder="E.g 4599"
              />
            </div>
            <div :class="store.role === 'Marketer' ? 'col-12' : 'col-md-6'">
              <label class="form-label" htmlFor="reports-orders-status">Status *</label>
              <argon-select
                id="reports-orders-status"
                v-model="order.status"
                name="status"
                :options="[
                  { value: 'Ordered', label: 'Ordered' },
                  { value: 'Reviewed', label: 'Reviewed' },
                  { value: 'Feedbacked', label: 'Feedbacked' },
                  { value: 'Corrected', label: 'Corrected' },
                  { value: 'On-Hold', label: 'On-Hold' },
                  { value: 'Refund Delayed', label: 'Refund Delayed' },
                  { value: 'Review Deleted', label: 'Review Deleted' },
                  { value: 'Feedback Sent', label: 'Feedback Sent' },
                  { value: 'Sent to Seller', label: 'Sent to Seller' },
                  { value: 'Feedback Rfnd', label: 'Feedback Rfnd' },
                  { value: 'Review Awaited', label: 'Review Awaited' },
                  { value: 'Refunded', label: 'Refunded' },
                  { value: 'Cancelled', label: 'Cancelled' },
                  { value: 'Comsn Processing', label: 'Comsn Processing' },
                  { value: 'Completed', label: 'Completed' },
                ]"
              />
            </div>
          </div>

          <!--    Submit/Cancel      -->
          <div class="mt-4 d-flex justify-content-end">
            <button
              type="button"
              name="button"
              class="m-0 btn bg-gradient-primary ms-2"
              :disabled="ordersLoading"
              @click="generateOrdersReport"
            >
              <i v-if="ordersLoading" class="fa fa-refresh fa-spin" />
              <span v-else>Generate</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Libs
import to from 'await-to-js';
import { useToast } from 'vue-toastification';

// Components
import ArgonSelect from '@/components/ArgonSelect.vue';
import ArgonInput from '@/components/ArgonInput.vue';

// Utils
import removeEmptyFields from '@/utils/removeEmptyFields';
import { parseQueryString } from '@/utils/queryString';

const toast = useToast();

export default {
  name: 'Reports',
  components: { ArgonInput, ArgonSelect },
  inject: ['store', 'actions'],
  data() {
    return {
      productsLoading: false,
      ordersLoading: false,
      product: {
        from: '',
        to: '',
        manager_uid: '',
        status: 'Active',
      },
      order: {
        from: '',
        to: '',
        user_id: '',
        status: 'Ordered',
      },
    };
  },
  methods: {
    triggerDownload(csvContent, filename) {
      // Create a Blob with CSV data
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

      // Create a URL for the blob
      const url = URL.createObjectURL(blob);

      // Create a temporary link element and trigger the download
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    },
    async generateProductsReport() {
      const search = removeEmptyFields({ ...this.product });
      const queryString = parseQueryString(search);

      const validated = ['from', 'to', 'status'].every((val) => search[val]);

      if (!validated) {
        return toast.error('Fields with * are required');
      }

      this.productsLoading = true;

      const [err, data] = await to(this.actions.api('get', `/products/export/?${queryString}`));

      this.productsLoading = false;

      if (!err) {
        this.triggerDownload(data, 'Products.csv');
      }
    },
    async generateOrdersReport() {
      const search = removeEmptyFields({ ...this.order });
      const queryString = parseQueryString(search);

      const validated = ['from', 'to', 'status'].every((val) => search[val]);

      if (!validated) {
        return toast.error('Fields with * are required');
      }

      this.ordersLoading = true;

      const [err, data] = await to(this.actions.api('get', `/orders/export/?${queryString}`));

      this.ordersLoading = false;

      if (!err) {
        this.triggerDownload(data, 'Orders.csv');
      }
    },
  },
};
</script>
