export default [
  {
    value: '',
    label: 'Bank Name',
  },
  {
    value: 'EasyPaisa',
    label: 'EasyPaisa',
  },
  {
    value: 'JazzCash',
    label: 'JazzCash',
  },
  {
    value: 'PayPal',
    label: 'PayPal',
  },
  {
    value: 'Advance Microfinance',
    label: 'Advance Microfinance',
  },
  {
    value: 'Al Baraka Bank Limited',
    label: 'Al Baraka Bank Limited',
  },
  {
    value: 'Allied Bank Limited',
    label: 'Allied Bank Limited',
  },
  {
    value: 'Apna Microfinance Bank',
    label: 'Apna Microfinance Bank',
  },
  {
    value: 'Askari Bank Limited',
    label: 'Askari Bank Limited',
  },
  {
    value: 'Bank Al Habib Limited',
    label: 'Bank Al Habib Limited',
  },
  {
    value: 'Bank Alfalah Limited',
    label: 'Bank Alfalah Limited',
  },
  {
    value: 'Bank of Khyber',
    label: 'Bank of Khyber',
  },
  {
    value: 'Bank of Punjab',
    label: 'Bank of Punjab',
  },
  {
    value: 'BankIslami Bank',
    label: 'BankIslami Bank',
  },
  {
    value: 'Burj Bank Limited',
    label: 'Burj Bank Limited',
  },
  {
    value: 'CitiBank',
    label: 'CitiBank',
  },
  {
    value: 'Dubai Islamic Bank',
    label: 'Dubai Islamic Bank',
  },
  {
    value: 'Faysal Bank Limited',
    label: 'Faysal Bank Limited',
  },
  {
    value: 'FINCA',
    label: 'FINCA',
  },
  {
    value: 'First Women Bank',
    label: 'First Women Bank',
  },
  {
    value: 'Habib Bank Limited',
    label: 'Habib Bank Limited',
  },
  {
    value: 'Habib Metropolitan Bank Limited',
    label: 'Habib Metropolitan Bank Limited',
  },
  {
    value: 'ICBC',
    label: 'ICBC',
  },
  {
    value: 'JS Bank Limited',
    label: 'JS Bank Limited',
  },
  {
    value: 'KASB Bank Limited',
    label: 'KASB Bank Limited',
  },
  {
    value: 'MCB Islamic Bank Limited',
    label: 'MCB Islamic Bank Limited',
  },
  {
    value: 'Meezan Bank Limited',
    label: 'Meezan Bank Limited',
  },
  {
    value: 'Mobilink Microfinance',
    label: 'Mobilink Microfinance',
  },
  {
    value: 'Muslim Commercial Bank',
    label: 'Muslim Commercial Bank',
  },
  {
    value: 'National Bank of Pakistan',
    label: 'National Bank of Pakistan',
  },
  {
    value: 'NRSP',
    label: 'NRSP',
  },
  {
    value: 'SAMBA',
    label: 'SAMBA',
  },
  {
    value: 'Silk Bank',
    label: 'Silk Bank',
  },
  {
    value: 'Sindh Bank',
    label: 'Sindh Bank',
  },
  {
    value: 'Soneri Bank Limited',
    label: 'Soneri Bank Limited',
  },
  {
    value: 'Standard Chartered Bank',
    label: 'Standard Chartered Bank',
  },
  {
    value: 'Summit Bank',
    label: 'Summit Bank',
  },
  {
    value: 'Telenor Microfinance Bank Limited',
    label: 'Telenor Microfinance Bank Limited',
  },
  {
    value: 'The First Microfinance Bank Limited',
    label: 'The First Microfinance Bank Limited',
  },
  {
    value: 'U Microfinance',
    label: 'U Microfinance',
  },
  {
    value: 'United Bank Limited',
    label: 'United Bank Limited',
  },
];
