<template>
  <main class="main-content main-content-bg mt-0">
    <div
      class="page-header min-vh-100"
      style="
        background-image: url('https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/reset-basic.jpg');
      "
    >
      <span class="mask bg-gradient-dark opacity-6"></span>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-xxl-4 col-md-7">
            <div v-show="tokenVerified" class="card z-index-0 mb-7">
              <div class="card-header text-center pt-4 pb-1">
                <h4 class="font-weight-bolder mb-1">Reset password</h4>
                <p class="mb-0">Create your new password.</p>
              </div>
              <div class="card-body">
                <form role="form" @submit.prevent="handleSubmit">
                  <argon-input
                    id="reset-password"
                    v-model="newPassword"
                    type="password"
                    placeholder="New Password"
                    is-required
                    aria-label="New Password"
                  />
                  <argon-input
                    id="reset-confirm-password"
                    v-model="confirmPassword"
                    type="password"
                    placeholder="Confirm New Password"
                    is-required
                    aria-label="Confirm New Password"
                  />
                  <div class="text-center">
                    <argon-button
                      color="dark"
                      variant="gradient"
                      class="my-4 mb-2"
                      :is-disabled="loading"
                      full-width
                    >
                      <i v-if="loading" class="fa fa-refresh fa-spin" />
                      <span v-else>Reset Password</span>
                    </argon-button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <app-footer />
</template>

<script>
// Libs
import router from '@/router';
import { useToast } from 'vue-toastification';
import { useRoute } from 'vue-router';
import to from 'await-to-js';

// Component
import AppFooter from '@/components/AuthFooter.vue';
import ArgonInput from '@/components/ArgonInput.vue';
import ArgonButton from '@/components/ArgonButton.vue';

// Init
const toast = useToast();
const body = document.getElementsByTagName('body')[0];

export default {
  name: 'ResetPassword',
  components: {
    AppFooter,
    ArgonInput,
    ArgonButton,
  },
  inject: ['store', 'actions'],
  data() {
    const { params } = useRoute();

    return {
      loading: false,
      token: params.token,
      tokenVerified: false,
      newPassword: '',
      confirmPassword: '',
    };
  },
  created() {
    this.store.hideConfigButton = true;
    this.actions.toggleDefaultLayout();
    body.classList.remove('bg-gray-200');
  },
  beforeUnmount() {
    this.store.hideConfigButton = false;
    this.actions.toggleDefaultLayout();
    body.classList.add('bg-gray-200');
  },
  async mounted() {
    const [, data] = await to(this.actions.api('get', `/auth/verify-token/${this.token}`));

    if (!data) {
      toast.error('Invalid or Expired token');
      router.push('/auth/login');
    } else {
      this.tokenVerified = true;
    }
  },
  methods: {
    async handleSubmit() {
      const { newPassword, confirmPassword } = this;
      if (!newPassword || !confirmPassword) {
        return toast.error('Passwords are required');
      }

      if (newPassword.length < 8) {
        return toast.error('Password length should be at least 8');
      }

      if (newPassword !== confirmPassword) {
        return toast.error('Password and Confirm Password are not same');
      }

      this.loading = true;

      const [err] = await to(
        this.actions.api('put', `/auth/reset-password/${this.token}`, { password: newPassword }),
      );

      this.loading = false;

      if (!err) {
        toast.success('Password reset successful, please login');
        this.newPassword = '';
        this.confirmPassword = '';
        router.push('/auth/login');
      }
    },
  },
};
</script>
