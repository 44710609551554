<template>
  <div class="container-fluid py-5 px-2 px-sm-4">
    <div class="row">
      <div class="col-12">
        <div class="multisteps-form">
          <div class="row">
            <div class="col-12 col-lg-8 mx-auto mb-4">
              <div class="card">
                <div class="card-body">
                  <div class="multisteps-form__progress">
                    <button
                      class="multisteps-form__progress-btn js-active"
                      type="button"
                      title="User Info"
                      :class="activeStep >= 0 ? activeClass : ''"
                      @click="activeStep = 0"
                    >
                      <span>User Info</span>
                    </button>
                    <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Address"
                      :class="activeStep >= 1 ? activeClass : ''"
                      @click="activeStep = 1"
                    >
                      Address
                    </button>
                    <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Referral Info"
                      :class="activeStep >= 2 ? activeClass : ''"
                      @click="activeStep = 2"
                    >
                      Referral Info
                    </button>
                    <button
                      class="multisteps-form__progress-btn"
                      type="button"
                      title="Bank Account"
                      :class="activeStep >= 3 ? activeClass : ''"
                      @click="activeStep = 3"
                    >
                      Bank Account
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--form panels-->
          <div class="row">
            <div class="col-12 col-lg-8 m-auto">
              <form class="multisteps-form__form">
                <!--single form panel-->
                <user-info :class="activeStep === 0 ? activeClass : ''" />
                <!--single form panel-->
                <user-address :class="activeStep === 1 ? activeClass : ''" />
                <!--single form panel-->
                <user-socials :class="activeStep === 2 ? activeClass : ''" />
                <!--single form panel-->
                <user-profile :class="activeStep === 3 ? activeClass : ''" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Libs
import { useToast } from 'vue-toastification';
import to from 'await-to-js';

// Components
import UserInfo from './components/UserInfo.vue';
import UserAddress from './components/UserAddress.vue';
import UserSocials from './components/UserReferral.vue';
import UserProfile from './components/UserBank.vue';

// Init
import removeEmptyFields from '@/utils/removeEmptyFields';
const toast = useToast();

export default {
  name: 'NewUser',
  components: {
    UserInfo,
    UserAddress,
    UserSocials,
    UserProfile,
  },
  inject: ['actions'],
  data() {
    return {
      showMenu: false,
      activeClass: 'js-active position-relative',
      activeStep: 0,
      formSteps: 3,
      loading: false,
      user: {
        first_name: '',
        last_name: '',
        email: '',
        cnic_number: '',
        password: '',
        confirmPassword: '',
        whatsapp_number: '',
        va_number: '',
        gender: 'Male',
        role: 'Marketer',
        address: {
          street: '',
          city: '',
          country: '',
        },
        referral: {
          name: '',
          email: '',
          contact: '',
        },
        bank: {
          name: '',
          title: '',
          account: '',
        },
      },
    };
  },
  methods: {
    nextStep() {
      if (this.activeStep < this.formSteps) {
        this.activeStep += 1;
      } else {
        this.activeStep -= 1;
      }
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep -= 1;
      }
    },
    async handleSubmit() {
      const user = removeEmptyFields({ ...this.user });

      const validated = [
        'first_name',
        'last_name',
        'email',
        'cnic_number',
        'whatsapp_number',
        'password',
        'confirmPassword',
        'role',
        'gender',
      ].every((val) => user[val]);

      if (!validated) {
        return toast.error('Fields with * are required');
      }

      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(user.email)) {
        return toast.error('Email is not valid');
      }

      if (user.password.length < 8) {
        return toast.error('Password length should be at least 8');
      }

      if (user.password !== user.confirmPassword) {
        return toast.error('Password and Confirm Password are not same');
      }

      user.cnic_number = user.cnic_number.replaceAll('-', '');

      if (user.cnic_number.length !== 13) {
        return toast.error('CNIC number is not correct');
      }

      this.loading = true;

      const [, data] = await to(this.actions.api('post', '/users', user));

      this.loading = false;

      if (data) {
        toast.success('User has been created');
        this.activeStep = 0;
        this.user = {
          ...this.user,
          first_name: '',
          last_name: '',
          email: '',
          cnic_number: '',
          password: '',
          confirmPassword: '',
          whatsapp_number: '',
          va_number: '',
          address: {
            street: '',
            city: '',
          },
          referral: {
            name: '',
            email: '',
            contact: '',
          },
          bank: {
            title: '',
            account: '',
          },
        };
      }
    },
  },
};
</script>
