<template>
  <div class="py-4 px-1 px-sm-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="row">
              <div class="col-sm-4 col-xxl-6">
                <h5 class="mb-0 text-center text-sm-start">{{ $route.name }}</h5>
              </div>
              <div class="col-sm-8 col-xxl-6">
                <div class="d-flex justify-content-center justify-content-sm-end mt-4 mt-sm-0">
                  <div v-show="$route.name === 'Active Users'" class="mx-1">
                    <router-link to="/users/add" class="mb-0 btn bg-gradient-primary btn-sm">
                      + New User
                    </router-link>
                  </div>
                  <div>
                    <button
                      class="mb-0 btn btn-outline-primary btn-sm"
                      @click="showFilters = !showFilters"
                    >
                      <i class="fas fa-filter"></i>
                      Filters
                      <i v-if="showFilters" class="ms-1 fas fa-angle-up"></i>
                      <i v-else class="ms-1 fas fa-angle-down"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <UserFilters v-if="showFilters" />
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table id="users-list" class="table align-items-center mb-0">
                <thead class="thead-light">
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      ID
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Name
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Email
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Gender
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      City
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Country
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Role
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-show="loading && !store.users[status].length">
                    <td colspan="8">
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr v-show="!loading && !store.users[status].length">
                    <td colspan="8">
                      <div class="d-flex justify-content-center">
                        <p class="text-monospace">No data found!</p>
                      </div>
                    </td>
                  </tr>

                  <tr v-for="user in store.users[status]" :key="user._id">
                    <td class="text-sm ps-4 py-3">{{ user.user_id }}</td>

                    <td>
                      <div class="d-flex">
                        <argon-avatar
                          :img="
                            user.profile_pic
                              ? `${mediaUrl}${user.profile_pic}`
                              : user.gender === 'Male'
                              ? malePlaceholder
                              : femalePlaceholder
                          "
                          size="sm"
                          alt="avatar image"
                          border-radius="lg"
                        />
                        <h6 class="my-auto ms-3">
                          {{ actions.shortenString(`${user.first_name} ${user.last_name}`) }}
                        </h6>
                      </div>
                    </td>
                    <td class="text-sm" :title="user.email">
                      {{ actions.shortenString(user.email) }}
                    </td>
                    <td class="text-sm">{{ user.gender }}</td>
                    <td class="text-sm">
                      {{ user.address?.city }}
                    </td>
                    <td class="text-sm">
                      {{ user.address?.country }}
                    </td>
                    <td class="text-sm">{{ user.role }}</td>
                    <td class="text-sm">
                      <router-link :to="`/users/${user.user_id}`" title="View User">
                        <i class="fas fa-eye text-secondary"></i>
                      </router-link>

                      <span
                        v-if="status === 'Active'"
                        title="Restrict User"
                        @click="restrictUser(user)"
                      >
                        <i
                          class="fas fa-user-slash text-secondary mx-3"
                          :class="user.role === 'Admin' ? 'opacity-5' : 'cursor-pointer'"
                        />
                      </span>

                      <span
                        v-if="status === 'Active' && !actions.isRestricted(user)"
                        title="Terminate User"
                        @click="terminateUser(user._id)"
                      >
                        <i class="fas fa-trash text-secondary cursor-pointer" />
                      </span>

                      <span
                        v-else-if="!actions.isRestricted(user)"
                        title="Activate User"
                        @click="activateUser(user._id)"
                      >
                        <i class="fas fa-user-check text-secondary mx-3 cursor-pointer" />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination
              :total-count="totalItems"
              :current-page="currentPage"
              :on-page-change="pageChange"
              :page-size="20"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Libs
import to from 'await-to-js';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';

// Components
import ArgonAvatar from '@/components/ArgonAvatar.vue';
import Pagination from '@/components/Pagination.vue';
import router from '@/router';

// Assets
import malePlaceholder from '@/assets/img/male.webp';
import femalePlaceholder from '@/assets/img/female.webp';
import removeEmptyFields from '@/utils/removeEmptyFields';
import { parseQueryString } from '@/utils/queryString';
import UserFilters from '@/views/users/components/UserFilters.vue';

const toast = useToast();

export default {
  name: 'UsersList',
  components: { UserFilters, Pagination, ArgonAvatar },
  inject: ['store', 'actions'],
  data() {
    const { query, name } = useRoute();
    return {
      mediaUrl: process.env.VUE_APP_MEDIA_URL,
      malePlaceholder,
      femalePlaceholder,
      loading: false,
      currentPage: parseInt(query.page) || 1,
      totalItems: 0,
      status: name.split(' ')[0],
      showFilters: false,
      searchParams: {
        role: query.role || '',
        query: query.query || '',
      },
    };
  },
  watch: {
    '$route.name'(name) {
      this.status = name.split(' ')[0];
      this.currentPage = 1;
      this.showFilters = false;
      this.searchParams = {
        role: '',
        query: '',
      };
      this.fetchData();
    },
    showFilters(newVal) {
      const search = removeEmptyFields({ ...this.searchParams });

      if (!newVal && Object.keys(search).length) {
        this.clearSearchFilters();
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData(page = this.currentPage) {
      const search = removeEmptyFields({ ...this.searchParams });
      const queryString = parseQueryString(search);

      this.loading = true;

      const [, data] = await to(
        this.actions.api('get', `/users/?status=${this.status}&page=${page}&${queryString}`),
      );

      this.loading = false;

      if (data?.success) {
        this.store.users[this.status] = data.users;
      }

      this.totalItems = data.totalItems || 0;
    },
    pageChange(page) {
      const search = removeEmptyFields({ ...this.searchParams });
      const queryString = parseQueryString(search);
      this.store.users[this.status] = [];
      this.currentPage = page;
      router.push(`${window.location.pathname}?page=${page}&${queryString}`);
      this.fetchData(page);
    },
    clearSearchFilters() {
      router.push(`${window.location.pathname}?page=1`);
      this.currentPage = 1;
      this.searchParams = {
        role: '',
        query: '',
      };
      this.fetchData(this.currentPage);
    },
    async terminateUser(id) {
      const [err] = await to(this.actions.api('patch', `/users/terminate/${id}`));

      if (!err) {
        toast.success('User terminated successfully');
        this.fetchData();
      }
    },
    async restrictUser(user) {
      if (user.role === 'Admin') {
        return;
      }

      const [err] = await to(this.actions.api('patch', `/users/restrict/${user._id}`));

      if (!err) {
        toast.success('User restricted successfully');
        this.fetchData();
      }
    },
    async activateUser(id) {
      const [err] = await to(this.actions.api('patch', `/users/activate/${id}`));

      if (!err) {
        toast.success('User activated successfully');
        this.fetchData();
      }
    },
  },
};
</script>
