<template>
  <div class="px-0 pb-0 card-body">
    <div class="table-responsive">
      <table class="table align-items-center mb-0">
        <thead>
          <tr>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Rank #
            </th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
              PM Name & ID
            </th>
            <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
              Earning
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Bonus
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Completed
            </th>
            <th
              class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              Placed
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-show="$parent.loading && !store.leaderboard.length">
            <td colspan="6">
              <div class="d-flex justify-content-center">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </td>
          </tr>

          <tr v-show="!$parent.loading && !store.leaderboard.length">
            <td colspan="6">
              <div class="d-flex justify-content-center">
                <p class="text-monospace">Evaluation is not done yet!</p>
              </div>
            </td>
          </tr>

          <tr v-for="item in store.leaderboard" :key="item._id">
            <td class="align-middle text-center">
              <p class="text-sm text-secondary mb-0">{{ item.rank }}</p>
            </td>
            <td>
              <div class="d-flex px-2 py-1">
                <argon-avatar
                  :img="
                    item.marketer?.profile_pic
                      ? `${mediaUrl}${item.marketer.profile_pic}`
                      : item.marketer?.gender === 'Male'
                      ? malePlaceholder
                      : femalePlaceholder
                  "
                  class="me-3"
                  size="sm"
                  alt="avatar image"
                  border-radius="lg"
                />
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">
                    {{ item.marketer?.first_name }} {{ item.marketer?.last_name }}
                    <i
                      v-show="item.marketer?.isPrime"
                      title="This marketer has a prime account"
                      class="fas fa-crown"
                      style="color: gold"
                    ></i>
                  </h6>
                  <p class="text-sm text-secondary mb-0">User ID: {{ item.marketer?.user_id }}</p>
                </div>
              </div>
            </td>
            <td>
              <p class="text-sm text-secondary mb-0">
                Rs
                {{
                  new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 }).format(
                    item.earning,
                  )
                }}
              </p>
            </td>
            <td class="align-middle text-center text-sm">
              <p class="text-secondary mb-0 text-sm">
                Rs
                {{
                  new Intl.NumberFormat('en-US', { maximumSignificantDigits: 3 }).format(item.bonus)
                }}
              </p>
            </td>
            <td class="align-middle text-center">
              <span class="text-secondary text-sm">{{ item.completed }}</span>
            </td>
            <td class="align-middle text-center">
              <span class="text-secondary text-sm">{{ item.placed }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ArgonAvatar from '@/components/ArgonAvatar.vue';
import malePlaceholder from '@/assets/img/male.webp';
import femalePlaceholder from '@/assets/img/female.webp';

export default {
  name: 'LeaderboardTable',
  components: {
    ArgonAvatar,
  },
  inject: ['store'],
  data() {
    return {
      mediaUrl: process.env.VUE_APP_MEDIA_URL,
      malePlaceholder,
      femalePlaceholder,
    };
  },
};
</script>
