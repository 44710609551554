<template>
  <div
    v-if="store.layout === 'custom'"
    class="position-absolute w-100 min-height-300 top-0"
    :style="{
      'background-image': 'url(' + image + ')',
      'background-position-y': '50%',
    }"
  >
    <span class="mask bg-success opacity-6"></span>
  </div>
  <div
    v-show="store.layout === 'default'"
    class="min-height-300 position-absolute w-100"
    :class="`${store.darkMode ? 'bg-trasnparent' : 'bg-gradient-custom'}`"
  />

  <aside
    id="sidenav-main"
    class="sidebar sidenav navbar navbar-vertical navbar-expand-xs border-0 border-radius-xl my-3 ms-4 fixed-start ms-3"
    :class="` ${store.sidebarType} ${
      store.layout === 'landing' ? 'bg-transparent shadow-none' : ''
    }`"
    data-color="primary"
  >
    <div class="sidenav-header">
      <i
        id="iconSidenav"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
        aria-hidden="true"
      ></i>
      <router-link class="m-0 navbar-brand" to="/dashboard">
        <img
          :src="
            store.layout === 'landing' || store.darkMode || store.sidebarType === 'bg-default'
              ? logoWhite
              : logo
          "
          class="navbar-brand-img h-100"
          alt="main_logo"
        />
        <span class="ms-2 font-weight-bold">GlobeMart</span>
      </router-link>
    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list />
  </aside>
</template>
<script>
import SidenavList from './SidenavList.vue';
import logo from '@/assets/img/logos/icon.png';
import logoWhite from '@/assets/img/logos/icon.png';
import image from '@/assets/img/profile-layout-header.jpg';

export default {
  name: 'Index',
  components: {
    SidenavList,
  },
  inject: ['store', 'actions'],
  data() {
    return {
      logo,
      logoWhite,
      image,
    };
  },
  beforeUnmount() {
    this.store.image;
  },
};
</script>

<style>
/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.bg-gradient-custom {
  background-image: linear-gradient(
    38deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 35%,
    rgba(0, 212, 255, 1) 100%
  );
}
</style>
