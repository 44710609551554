<template>
  <div class="py-4 px-1 px-sm-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="row">
              <div class="col-6">
                <h5 class="mb-0">Blacklist PayPal</h5>
              </div>
              <div v-if="store.role !== 'Marketer'" class="col-6">
                <div class="w-100 d-flex justify-content-end">
                  <button
                    type="button"
                    class="mx-1 mb-0 btn btn-outline-primary btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target="#add-blacklist"
                  >
                    Add Entry
                  </button>
                  <div id="add-blacklist" class="modal fade" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog mt-lg-10">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 id="ModalLabel" class="modal-title">Add entry</h5>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <i class="fas fa-times text-secondary"></i>
                          </button>
                        </div>
                        <form @submit.prevent="handleSubmit">
                          <div class="modal-body">
                            <p>Add buyer paypal to blacklist.</p>
                            <argon-input
                              v-model="email"
                              type="text"
                              placeholder="Enter paypal email"
                            />
                          </div>
                          <div class="modal-footer">
                            <button
                              id="add-blacklist-close"
                              type="button"
                              class="btn bg-gradient-secondary btn-sm"
                              data-bs-dismiss="modal"
                              @click="email = ''"
                            >
                              Close
                            </button>
                            <button
                              type="submit"
                              class="btn bg-gradient-primary btn-sm"
                              :disabled="addLoader"
                            >
                              <i v-if="addLoader" class="fa fa-refresh fa-spin" />
                              <span v-else>Add</span>
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table id="blacklist-table" class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Email
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Added By
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
                    >
                      Date Created
                    </th>
                    <th
                      v-if="store.role !== 'Marketer'"
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-show="loading && !store.blacklist.length">
                    <td colspan="8">
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr v-show="!loading && !store.blacklist.length">
                    <td colspan="8">
                      <div class="d-flex justify-content-center">
                        <p class="text-monospace">No data found!</p>
                      </div>
                    </td>
                  </tr>

                  <tr v-for="item in store.blacklist" :key="item._id" style="height: 50px">
                    <td class="ps-4 py-3 align-middle text-left">
                      <p class="text-sm text-secondary mb-0">{{ item.email }}</p>
                    </td>
                    <td class="align-middle text-center">
                      <p class="text-sm text-secondary mb-0">
                        <router-link :to="`/users/${item.added_by?.user_id}`">
                          {{ item.added_by?.first_name }} {{ item.added_by?.last_name }} ({{
                            item.added_by?.role
                          }})
                        </router-link>
                      </p>
                    </td>
                    <td class="align-middle text-center">
                      <p class="text-sm text-secondary mb-0">
                        {{ new Date(item.createdAt)?.toLocaleString() }}
                      </p>
                    </td>
                    <td v-if="store.role !== 'Marketer'" class="text-sm text-center">
                      <span title="Remove from blacklist" @click="deleteItem(item._id)">
                        <i class="cursor-pointer fas fa-trash text-secondary" />
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination
              :total-count="totalItems"
              :current-page="currentPage"
              :on-page-change="pageChange"
              :page-size="20"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import ArgonInput from '@/components/ArgonInput.vue';
import { useRoute } from 'vue-router';
import to from 'await-to-js';
import router from '@/router';
import Pagination from '@/components/Pagination.vue';

const toast = useToast();

export default {
  name: 'Blacklist',
  components: { Pagination, ArgonInput },
  inject: ['store', 'actions'],
  data() {
    const { query } = useRoute();
    return {
      loading: false,
      addLoader: false,
      currentPage: parseInt(query.page) || 1,
      totalItems: 0,
      email: '',
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData(page = this.currentPage) {
      this.loading = true;
      const [, data] = await to(this.actions.api('get', `/blacklist/?page=${page}`));
      this.loading = false;

      if (data?.success) {
        this.store.blacklist = data.blacklist;
        this.totalItems = data.totalItems || 0;
      }
    },
    pageChange(page) {
      this.store.blacklist = [];
      this.currentPage = page;
      router.push(`/blacklist/?page=${page}`);
      this.fetchData(page);
    },
    async handleSubmit() {
      if (!this.email) {
        return toast.error('Email is not provided');
      }

      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email)) {
        return toast.error('Email is not valid');
      }

      this.addLoader = true;
      const [err] = await to(this.actions.api('post', `/blacklist`, { email: this.email }));
      this.addLoader = false;

      if (!err) {
        document.getElementById('add-blacklist-close').click();
        toast.success('Email added into blacklist');
        this.email = '';
        this.fetchData();
      }
    },
    async deleteItem(id) {
      const [err] = await to(this.actions.api('delete', `/blacklist/${id}`));

      if (!err) {
        toast.success('Email removed from blacklist');
        this.fetchData();
      }
    },
  },
};
</script>
