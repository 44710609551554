<template>
  <!-- Profile -->
  <div id="profile" class="card card-body mt-4">
    <div class="row justify-content-center align-items-center">
      <div class="col-sm-auto col-3">
        <input
          id="profile-profile_pic"
          type="file"
          accept=".png, .jpg, .jpeg, .webp, .gif"
          hidden
          @change="updateProfilePic"
        />
        <!--        user.profile_pic ? `${mediaUrl}${user.profile_pic}` :-->
        <argon-avatar
          class="cursor-pointer overflow-hidden"
          :img="
            user.profile_pic
              ? `${mediaUrl}${user.profile_pic}`
              : user.gender === 'Male'
              ? malePlaceholder
              : femalePlaceholder
          "
          alt="team-3"
          size="xl"
          shadow="sm"
          border-radius="lg"
          @click="openFilePicker"
        />
      </div>
      <div class="col-sm-auto col-9 my-auto">
        <div class="h-100">
          <h5 class="mb-1 font-weight-bolder">
            {{ store.user?.first_name }} {{ store.user?.last_name }}
          </h5>
          <p class="mb-0 font-weight-bold text-sm">{{ user.role }} (User ID: {{ user.user_id }})</p>
        </div>
      </div>
      <div class="col-sm-auto ms-sm-auto mt-sm-0 mt-3 d-flex">
        <label class="form-check-label mb-0 me-2" htmlFor="edit-mode">
          <small id="editing-mode">Edit Mode</small>
        </label>
        <argon-switch
          id="edit-mode"
          name="editing"
          :checked="editing"
          @change="editing = !editing"
        />
      </div>
    </div>
  </div>

  <!-- Basic Info -->
  <div id="basic-info" class="card mt-4">
    <div class="card-header">
      <h5>Basic Info</h5>
      <p class="text-sm">Update the basic information about your profile.</p>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-md-6 my-1">
          <label class="form-label" htmlFor="profile-firstname">
            First Name {{ editing ? '*' : '' }}
          </label>
          <argon-input
            id="profile-firstname"
            v-model="user.first_name"
            type="text"
            :placeholder="editing ? 'Alec' : ''"
            :is-disabled="!editing"
          />
        </div>
        <div class="col-md-6 my-1">
          <label class="form-label" htmlFor="profile-lastname">
            Last Name {{ editing ? '*' : '' }}
          </label>
          <argon-input
            id="profile-lastname"
            v-model="user.last_name"
            type="text"
            :placeholder="editing ? 'Thompson' : ''"
            :is-disabled="!editing"
          />
        </div>
      </div>
      <div class="row">
        <div class="my-1" :class="user.role === 'Manager' ? 'col-md-4' : 'col-md-8'">
          <label class="form-label mt-2" htmlFor="profile-whatsapp-number">
            Whatsapp Number {{ editing ? '*' : '' }}
          </label>
          <argon-input
            id="profile-whatsapp-number"
            v-model="user.whatsapp_number"
            type="text"
            :placeholder="editing ? '+92 123 4567890' : ''"
            :is-disabled="!editing"
          />
        </div>
        <div v-if="user.role === 'Manager'" class="col-md-4 my-1">
          <label class="form-label mt-2" htmlFor="profile-va-number">VA Number</label>
          <argon-input
            id="profile-va-number"
            v-model="user.va_number"
            type="text"
            :placeholder="editing ? '+92 123 4567890' : ''"
            :is-disabled="!editing"
          />
        </div>
        <div class="col-md-4 my-1">
          <label class="form-label mt-2" htmlFor="profile-gender">
            Gender {{ editing ? '*' : '' }}
          </label>
          <argon-select
            id="profile-gender"
            v-model="user.gender"
            name="gender"
            :is-disabled="!editing"
            :use-choices="false"
            :options="[
              {
                value: 'Male',
                label: 'Male',
              },
              {
                value: 'Female',
                label: 'Female',
              },
            ]"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 my-1">
          <label class="form-label mt-2" htmlFor="profile-address">Address</label>
          <argon-input
            id="profile-address"
            v-model="user.address.street"
            type="text"
            :placeholder="editing ? 'Street 11, 54000, Sydney, A' : ''"
            :is-disabled="!editing"
          />
        </div>
        <div class="col-md-3 my-1">
          <label class="form-label mt-2" htmlFor="profile-city">City</label>
          <argon-input
            id="profile-city"
            v-model="user.address.city"
            type="text"
            :placeholder="editing ? 'Sydney, A' : ''"
            :is-disabled="!editing"
          />
        </div>
        <div class="col-md-3 my-1">
          <label class="form-label mt-2" htmlFor="profile-country">Country</label>
          <argon-select
            id="profile-country"
            v-model="user.address.country"
            name="country"
            :use-choices="false"
            :is-disabled="!editing"
            :options="countryDropdown"
          />
        </div>
      </div>
      <argon-button
        v-show="editing"
        class="float-end mt-3 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        :is-disabled="infoLoading"
        @click="updateInfo"
      >
        <i v-if="infoLoading" class="fa fa-refresh fa-spin" />
        <span v-else>Save</span>
      </argon-button>
    </div>
  </div>

  <!-- Referral Info -->
  <div id="referral-info" class="card mt-4">
    <div class="card-header">
      <h5>Referral Info</h5>
      <p class="text-sm">Add the referral info if you have one.</p>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-md-6 my-1">
          <label class="form-label" htmlFor="profile-referral-name">Referral Name</label>
          <argon-input
            id="profile-referral-name"
            v-model="user.referral.name"
            type="text"
            :placeholder="editing ? 'john Doe' : ''"
            :is-disabled="!editing"
          />
        </div>
        <div class="col-md-6 my-1">
          <label class="form-label" htmlFor="profile-referral-contact">Referral Contact</label>
          <argon-input
            id="profile-referral-contact"
            v-model="user.referral.contact"
            type="text"
            :placeholder="editing ? '+92 123 4567890' : ''"
            :is-disabled="!editing"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-12 my-1">
          <label class="form-label" htmlFor="profile-referral-id">Referral UserID/Email</label>
          <argon-input
            id="profile-referral-id"
            v-model="user.referral.email"
            type="text"
            :placeholder="editing ? 'abc@xyz.com' : ''"
            :is-disabled="!editing"
          />
        </div>
      </div>
      <argon-button
        v-show="editing"
        class="float-end mt-3 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        :is-disabled="referralLoading"
        @click="updateReferral"
      >
        <i v-if="referralLoading" class="fa fa-refresh fa-spin" />
        <span v-else>Save</span>
      </argon-button>
    </div>
  </div>

  <!-- Billing Info -->
  <div id="billing-info" class="card mt-4">
    <div class="card-header">
      <h5>Bank Account</h5>
      <p class="text-sm">Here you can setup and manage your bank accounts.</p>
    </div>
    <div class="card-body pt-0">
      <div class="row">
        <div class="col-12 my-1">
          <label class="form-label mt-2" htmlFor="profile-bank-name">
            Bank Name {{ editing ? '*' : '' }}
          </label>
          <argon-select
            id="profile-bank-name"
            v-model="user.bank.name"
            name="bank_name"
            :use-choices="false"
            :is-disabled="!editing"
            :options="banks"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 my-1">
          <label class="form-label" htmlFor="profile-account-title">
            Account Title {{ editing ? '*' : '' }}
          </label>
          <argon-input
            id="profile-account-title"
            v-model="user.bank.title"
            type="text"
            :placeholder="editing ? 'James R Beckley' : ''"
            :is-disabled="!editing"
          />
        </div>
        <div class="col-md-6 my-1">
          <label class="form-label" htmlFor="profile-account-number">
            Account Number {{ editing ? '*' : '' }}
          </label>
          <argon-input
            id="profile-account-number"
            v-model="user.bank.account"
            type="text"
            :placeholder="editing ? '2645826478264' : ''"
            :is-disabled="!editing"
          />
        </div>
      </div>
      <argon-button
        v-show="editing"
        class="float-end mt-3 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        :is-disabled="bankLoading"
        @click="updateBank"
      >
        <i v-if="bankLoading" class="fa fa-refresh fa-spin" />
        <span v-else>Save</span>
      </argon-button>
    </div>
  </div>

  <!-- Update Password -->
  <div v-if="editing && store.role === 'Admin'" id="password" class="card mt-4">
    <div class="card-header">
      <h5>Change Password</h5>
    </div>
    <div class="card-body pt-0">
      <label class="form-label" htmlFor="profile-password">
        Current password {{ editing ? '*' : '' }}
      </label>
      <argon-input
        id="profile-password"
        v-model="user.currentPassword"
        type="password"
        placeholder="Current Password"
        :is-disabled="!editing"
      />
      <label class="form-label" htmlFor="profile-new-password">
        New password {{ editing ? '*' : '' }}
      </label>
      <argon-input
        id="profile-new-password"
        v-model="user.newPassword"
        type="password"
        placeholder="New Password"
        :is-disabled="!editing"
      />
      <label class="form-label" htmlFor="profile-confirm-password">
        Confirm new password {{ editing ? '*' : '' }}
      </label>
      <argon-input
        id="profile-confirm-password"
        v-model="user.confirmPassword"
        type="password"
        placeholder="Confirm password"
        :is-disabled="!editing"
      />
      <h5 class="mt-5">Password requirements</h5>
      <p class="text-muted mb-2">Please follow this guide for a strong password:</p>
      <ul class="text-muted ps-4 mb-0 float-start">
        <li>
          <span class="text-sm">One special characters</span>
        </li>
        <li>
          <span class="text-sm">Min 6 characters</span>
        </li>
        <li>
          <span class="text-sm">One number (2 are recommended)</span>
        </li>
        <li>
          <span class="text-sm">Change it often</span>
        </li>
      </ul>
      <argon-button
        v-show="editing"
        class="float-end mt-6 mb-0"
        color="dark"
        variant="gradient"
        size="sm"
        :is-disabled="passwordLoading"
        @click="updatePassword"
      >
        <i v-if="passwordLoading" class="fa fa-refresh fa-spin" />
        <span v-else>Update Password</span>
      </argon-button>
    </div>
  </div>

  <!-- Sessions -->
  <div id="sessions" class="card mt-4">
    <div class="card-header pb-3">
      <h5>Sessions</h5>
      <p class="text-sm">This is a list of devices that have logged into your account.</p>
    </div>
    <div class="card-body pt-0">
      <div v-for="(activity, i) in user.activity" :key="i">
        <div class="d-flex align-items-center">
          <div class="text-center w-5">
            <i
              v-if="['iPhone', 'Android', 'Samsung', 'iOS'].some((_) => activity.includes(_))"
              class="fas fa-mobile text-lg opacity-6"
            ></i>
            <i v-else class="fas fa-desktop text-lg opacity-6"></i>
          </div>
          <div class="my-auto ms-3">
            <div class="h-100">
              <p class="text-sm mb-1">{{ activity }}</p>
              <p v-show="i === 0" class="mb-0 text-xs">Your current session</p>
            </div>
          </div>
          <argon-badge v-show="i === 0" color="success" size="sm" class="my-auto ms-auto me-3">
            Active
          </argon-badge>
        </div>
        <hr v-show="i < user.activity.length - 1" class="horizontal dark" />
      </div>
    </div>
  </div>

  <!-- Delete Account -->
  <div id="delete" class="card mt-4">
    <div class="card-header">
      <h5>Deactivate Account</h5>
      <p class="text-sm mb-0">
        Once you deactivate your account, some data maybe lost. Please be certain.
      </p>
    </div>
    <div class="card-body d-sm-flex pt-0">
      <div class="d-flex align-items-center mb-sm-0 mb-4">
        <div>
          <argon-switch id="deactivate-account" v-model="deactivate" name="Deactivate Account" />
        </div>
        <div class="ms-2">
          <span class="text-dark font-weight-bold d-block text-sm">Confirm</span>
          <span class="text-xs d-block">I want to deactivate my account.</span>
        </div>
      </div>

      <argon-button
        color="danger"
        variant="gradient"
        class="mb-0 ms-auto"
        type="button"
        name="button"
        :is-disabled="!deactivate || deactivateLoading"
        @click="deactivateAccount"
      >
        <i v-if="deactivateLoading" class="fa fa-refresh fa-spin" />
        <span v-else>Deactivate Account</span>
      </argon-button>
    </div>
  </div>
</template>

<script>
// Libs
import { useToast } from 'vue-toastification';
import to from 'await-to-js';

// Components
import ArgonButton from '@/components/ArgonButton.vue';
import ArgonSwitch from '@/components/ArgonSwitch.vue';
import ArgonBadge from '@/components/ArgonBadge.vue';
import ArgonAvatar from '@/components/ArgonAvatar.vue';
import ArgonInput from '@/components/ArgonInput.vue';
import ArgonSelect from '@/components/ArgonSelect.vue';

// Init
import { countryDropdown } from '@/utils/country';
import banks from '@/utils/banks';
import img from '@/assets/img/team-3.jpg';
import removeEmptyFields from '@/utils/removeEmptyFields';
import router from '@/router';
const toast = useToast();

// Assets
import malePlaceholder from '@/assets/img/male.webp';
import femalePlaceholder from '@/assets/img/female.webp';

export default {
  name: 'SideNavItem',
  components: {
    ArgonSelect,
    ArgonButton,
    ArgonSwitch,
    ArgonBadge,
    ArgonAvatar,
    ArgonInput,
  },
  inject: ['store', 'actions'],
  data() {
    return {
      banks,
      countryDropdown,
      malePlaceholder,
      femalePlaceholder,
      mediaUrl: process.env.VUE_APP_MEDIA_URL,
      editing: false,
      deactivate: false,
      infoLoading: false,
      referralLoading: false,
      bankLoading: false,
      passwordLoading: false,
      deactivateLoading: false,
      user: {
        _id: '',
        user_id: '',
        first_name: '',
        last_name: '',
        email: '',
        cnic_number: '',
        profile_pic: null,
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        whatsapp_number: '',
        va_number: '',
        gender: 'Male',
        role: 'Marketer',
        address: {
          street: '',
          city: '',
          country: '',
        },
        referral: {
          name: '',
          email: '',
          contact: '',
        },
        bank: {
          name: '',
          title: '',
          account: '',
        },
        activity: [],
      },
    };
  },
  mounted() {
    this.user = { ...this.user, ...this.store.user };
  },
  methods: {
    async updateProfilePic(e) {
      if (!e.target.files?.length) {
        return;
      }

      const formData = new FormData();
      formData.append('profile_pic', e.target.files[0]);

      const [, data] = await to(
        this.actions.api('put', `/users/update-pic/${this.user._id}`, formData),
      );

      document.getElementById(e.target.id).value = '';

      if (data?.user) {
        toast.success('Picture updated successfully');
        this.store.user = data.user;
        this.user.profile_pic = data.user?.profile_pic;
      }
    },
    async updateInfo() {
      const data = removeEmptyFields({
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        cnic_number: this.user.cnic_number,
        whatsapp_number: this.user.whatsapp_number,
        gender: this.user.gender,
        address: this.user.address,
      });
      data.va_number = this.user.va_number;

      const validated = [
        'first_name',
        'last_name',
        'email',
        'cnic_number',
        'whatsapp_number',
        'gender',
      ].every((val) => data[val]);

      if (!validated) {
        return toast.error('Fields with * are required');
      }

      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
        return toast.error('Email is not valid');
      }

      data.cnic_number = data.cnic_number.replaceAll('-', '');

      if (data.cnic_number.length !== 13) {
        return toast.error('CNIC number is not correct');
      }

      this.infoLoading = true;

      const [, res] = await to(
        this.actions.api('put', `/users/update-info/${this.user._id}`, data),
      );

      this.infoLoading = false;

      if (res) {
        toast.success('Profile has been updated');
        this.store.user = res.user;
      }
    },
    async updateReferral() {
      const data = removeEmptyFields({ referral: this.user.referral });

      this.referralLoading = true;

      const [, res] = await to(
        this.actions.api('put', `/users/update-referral/${this.user._id}`, data),
      );

      this.referralLoading = false;

      if (res) {
        toast.success('Profile has been updated');
        this.store.user = res.user;
      }
    },
    async updateBank() {
      const data = removeEmptyFields({ bank: this.user.bank });

      const validated = ['name', 'title', 'account'].every((val) => data.bank[val]);

      if (!validated) {
        return toast.error('Fields with * are required');
      }

      this.bankLoading = true;

      const [, res] = await to(
        this.actions.api('put', `/users/update-bank/${this.user._id}`, data),
      );

      this.bankLoading = false;

      if (res) {
        toast.success('Profile has been updated');
        this.store.user = res.user;
      }
    },
    async updatePassword() {
      const data = removeEmptyFields({
        currentPassword: this.user.currentPassword,
        newPassword: this.user.newPassword,
        confirmPassword: this.user.confirmPassword,
      });

      const validated = ['currentPassword', 'newPassword', 'confirmPassword'].every(
        (val) => data[val],
      );

      if (!validated) {
        return toast.error('Fields with * are required');
      }

      if (data.newPassword.length < 8) {
        return toast.error('Password length should be at least 8');
      }

      if (data.newPassword !== data.confirmPassword) {
        return toast.error('Password and Confirm Password are not same');
      }

      this.passwordLoading = true;

      const [, res] = await to(
        this.actions.api('put', `/users/update-password/${this.user._id}`, data),
      );

      this.passwordLoading = false;

      if (res) {
        toast.success('Password has been updated');
        this.store.user = res.user;
        this.user.currentPassword = '';
        this.user.newPassword = '';
        this.user.confirmPassword = '';
      }
    },
    async deactivateAccount() {
      if (!this.deactivate) {
        return toast.error('Please confirm you want to deactivate your account');
      }

      this.deactivateLoading = true;

      const [err] = await to(this.actions.api('patch', `/users/deactivate/${this.user._id}`));

      this.deactivateLoading = false;

      if (!err) {
        toast.success('Your account has been deactivated');
        setTimeout(() => {
          localStorage.removeItem('token');
          router.push('/auth/login');
        }, 3000);
      }
    },
    openFilePicker() {
      document.getElementById('profile-profile_pic').click();
    },
  },
};
</script>
