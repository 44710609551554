<template>
  <div class="avatar" :class="getSize(size)">
    <img
      :src="img"
      :alt="alt"
      :class="[getSize(size), getClasses(shadow, circular, borderRadius)]"
      style="object-fit: cover"
    />
  </div>
</template>

<script>
export default {
  name: 'ArgonAvatar',
  props: {
    img: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: null,
    },
    shadow: {
      type: String,
      default: null,
    },
    borderRadius: {
      type: String,
      default: null,
    },
    circular: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getSize: (size) => (size ? `avatar-${size}` : null),
    getClasses: (shadow, circular, borderRadius) => {
      let shadowValue, circularValue, borderRadiusValue;

      if (shadow) {
        shadowValue = shadow === 'regular' ? 'shadow' : `shadow-${shadow}`;
      } else {
        shadowValue = null;
      }

      circularValue = circular ? 'rounded-circle' : null;

      borderRadiusValue = borderRadius ? `border-radius-${borderRadius}` : null;

      return `${shadowValue} ${circularValue} ${borderRadiusValue}`;
    },
  },
};
</script>
