<template>
  <div class="card h-100">
    <div class="p-3 pb-0 card-header">
      <div class="d-flex justify-content-between">
        <h6 class="mb-0">Orders Overview</h6>
        <button
          type="button"
          class="mb-0 btn btn-icon-only btn-rounded btn-outline-secondary ms-2 btn-sm d-flex align-items-center justify-content-center"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title
          data-bs-original-title="See traffic channels"
        >
          <i class="fas fa-info" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="p-3 pb-0 mt-0 mt-sm-6 card-body">
      <div class="row">
        <div class="col-7 text-start">
          <div class="chart">
            <canvas :id="chartId" class="chart-canvas" height="200"></canvas>
          </div>
        </div>
        <div class="my-auto col-5">
          <span class="badge badge-md badge-dot me-4 d-block text-start">
            <i class="bg-info"></i>
            <span class="text-xs text-dark">Active</span>
          </span>
          <span class="badge badge-md badge-dot me-4 d-block text-start">
            <i class="bg-success"></i>
            <span class="text-xs text-dark">Completed</span>
          </span>
          <span class="badge badge-md badge-dot me-4 d-block text-start">
            <i class="bg-dark"></i>
            <span class="text-xs text-dark">Refunded</span>
          </span>
          <span class="badge badge-md badge-dot me-4 d-block text-start">
            <i class="bg-secondary"></i>
            <span class="text-xs text-dark">Cancelled</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
export default {
  name: 'PieChartCard',
  inject: ['store'],
  data() {
    return {
      chartId: 'chart-pie',
      myChart: null,
    };
  },

  mounted() {
    this.$watch('store.cards', () => {
      this.createChart();
    });
  },
  methods: {
    createChart() {
      if (this.myChart) {
        this.myChart.destroy();
      }

      const pieChart = document.getElementById(this.chartId).getContext('2d');

      let chartStatus = Chart.getChart(this.id);
      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      // Prepare data
      const dataValues = [
        this.store.cards[4].count,
        this.store.cards[15].count,
        this.store.cards[16].count,
        this.store.cards[18].count,
      ];

      // Proceed with creating the chart as there is data
      this.myChart = new Chart(pieChart, {
        type: 'pie',
        data: {
          labels: ['Active', 'Refunded', 'Cancelled', 'Completed'],
          datasets: [
            {
              label: 'Projects',
              weight: 9,
              cutout: 0,
              tension: 0.9,
              pointRadius: 2,
              borderWidth: 2,
              backgroundColor: ['#5e72e4', '#343a40', '#8392ab', '#2dce89'],
              data: dataValues,
              fill: false,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: 'index',
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
              ticks: {
                display: false,
              },
            },
          },
        },
      });
    },
  },
};
</script>
