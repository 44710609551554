<template>
  <div class="card multisteps-form__panel p-3 border-radius-xl bg-white" data-animation="FadeIn">
    <h5 class="font-weight-bolder mb-0">Address</h5>
    <p class="mb-0 text-sm">You have to enter the user address details here.</p>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col my-1">
          <label htmlFor="newuser-address">Address</label>
          <argon-input
            id="newuser-address"
            v-model="$parent.user.address.street"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. Street 111"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-md-7 my-1">
          <label htmlFor="newuser-city">City</label>
          <argon-input
            id="newuser-city"
            v-model="$parent.user.address.city"
            class="multisteps-form__input"
            type="text"
            placeholder="eg. Tokyo"
          />
        </div>
        <div class="col-sm-12 col-md-5 my-1">
          <label htmlFor="newuser-country">Country</label>
          <argon-select
            id="newuser-country"
            v-model="$parent.user.address.country"
            name="country"
            :options="countryDropdown"
          />
        </div>
      </div>
      <div class="button-row d-flex mt-3">
        <argon-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click="$parent.prevStep"
        >
          Prev
        </argon-button>
        <argon-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto js-btn-next"
          @click="$parent.nextStep"
        >
          Next
        </argon-button>
      </div>
    </div>
  </div>
</template>

<script>
import ArgonInput from '@/components/ArgonInput.vue';
import ArgonButton from '@/components/ArgonButton.vue';
import Choices from 'choices.js';
import ArgonSelect from '@/components/ArgonSelect.vue';
import { countryDropdown } from '@/utils/country';

export default {
  name: 'UserAddress',
  data() {
    return {
      countryDropdown,
    };
  },
  components: {
    ArgonSelect,
    ArgonInput,
    ArgonButton,
  },
  mounted() {
    if (document.getElementById('newuser-country')) {
      var element = document.getElementById('newuser-country');
      new Choices(element, {
        searchEnabled: false,
        allowHTML: true,
      });
    }
  },
};
</script>
