const reviewTypes = [
  {
    value: 'Review',
    label: 'Review',
  },
  {
    value: 'Top Review',
    label: 'Top Review',
  },
  {
    value: 'Rating',
    label: 'Rating',
  },
  {
    value: 'No Review',
    label: 'No Review',
  },
  {
    value: 'Feedback',
    label: 'Feedback',
  },
  {
    value: 'High Commission',
    label: 'High Commission',
  },
];

export default reviewTypes;
