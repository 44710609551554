const commissionTypes = [
  {
    value: 'Text',
    label: 'Text',
  },
  {
    value: 'Picture',
    label: 'Picture',
  },
  {
    value: 'Video',
    label: 'Video',
  },
];

export default commissionTypes;
