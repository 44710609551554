<template>
  <div class="py-4 px-1 px-sm-4 container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="pb-0 card-header">
            <div class="row">
              <div class="col-6">
                <h5 class="mb-0">All Orders</h5>
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div>
                    <button
                      class="mb-0 btn btn-outline-primary btn-sm"
                      @click="showFilters = !showFilters"
                    >
                      <i class="fas fa-filter"></i>
                      Filters
                      <i v-if="showFilters" class="ms-1 fas fa-angle-up"></i>
                      <i v-else class="ms-1 fas fa-angle-down"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <OrderFilters v-if="showFilters" />
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="table-responsive">
              <table id="orders-list" class="table align-items-center mb-0">
                <thead class="thead-light">
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Order
                    </th>
                    <th
                      colspan="3"
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Details
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 pe-5 pe-sm-0"
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-show="loading && !store.orders.length">
                    <td colspan="8">
                      <div class="d-flex justify-content-center">
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr v-show="!loading && !store.orders.length">
                    <td colspan="8">
                      <div class="d-flex justify-content-center">
                        <p class="text-monospace">No data found!</p>
                      </div>
                    </td>
                  </tr>

                  <tr v-for="order in store.orders" :key="order._id">
                    <td class="ps-4 py-3">
                      <img
                        class="order-image"
                        :src="`${mediaUrl}${order.product?.product_image}`"
                        alt="Product Image"
                      />
                    </td>
                    <td>
                      <p class="text-sm my-1">
                        <strong>Marketplace:</strong>
                        {{ country(order.product?.amz_marketplace) }}
                        <span v-show="order.product?.market_type !== 'Amazon'">
                          {{ order.product?.market_type }}
                        </span>
                      </p>
                      <p class="text-sm my-1" :title="order.amz_order_number">
                        <strong>AMZ Order:</strong>
                        {{ actions.shortenString(order.amz_order_number) }}
                      </p>
                      <p class="text-sm my-1">
                        <strong>Buyer PP:</strong>
                        {{ order.buyer_paypal }}
                      </p>
                    </td>
                    <td>
                      <p class="text-sm my-1">
                        <strong>ORD#:</strong>
                        {{ order.order_id }}
                      </p>
                      <p v-if="store.role === 'Marketer'" class="text-sm my-1">
                        <strong>Manager:&nbsp;</strong>
                        <router-link :to="`/users/${order.manager?.user_id}`">
                          {{ order.manager?.first_name }} {{ order.manager?.last_name }}
                        </router-link>
                        <WALink :phone="order.manager?.whatsapp_number" />
                      </p>
                      <p v-else class="text-sm my-1">
                        <strong>Marketer:&nbsp;</strong>
                        <router-link :to="`/users/${order.marketer?.user_id}`">
                          {{ order.marketer?.first_name }} {{ order.marketer?.last_name }}
                        </router-link>
                        <WALink :phone="order.marketer?.whatsapp_number" />
                      </p>
                      <p v-if="store.role === 'Admin'" class="text-sm my-1">
                        <strong>Manager:&nbsp;</strong>
                        <router-link :to="`/users/${order.manager?.user_id}`">
                          {{ order.manager?.first_name }} {{ order.manager?.last_name }}
                        </router-link>
                        <WALink :phone="order.manager?.whatsapp_number" />
                      </p>
                      <p v-else class="text-sm my-1">
                        <strong>Seller Code:</strong>
                        {{ order.seller_code || 'N/A' }}
                      </p>
                    </td>
                    <td>
                      <p class="text-sm my-1">
                        <strong>Status:&nbsp;</strong>
                        <order-badge :value="order.status" />
                      </p>
                      <p class="text-sm my-1">
                        <strong>MPIN#:</strong>
                        {{ order.product?.product_id }}&nbsp;
                        <router-link :to="`/products/${order.product?.product_id}`">
                          (View Details)
                        </router-link>
                      </p>
                      <p class="text-sm my-1">
                        <strong>Last Update:</strong>
                        {{ new Date(order.updatedAt)?.toLocaleString() }}
                      </p>
                    </td>

                    <td class="text-sm">
                      <span title="Copy info" @click="copyInfo(order)">
                        <i class="fas fa-copy text-secondary cursor-pointer" />
                      </span>

                      <router-link
                        :to="`/orders/${order.order_id}`"
                        title="View order"
                        class="mx-3"
                      >
                        <i class="fas fa-eye text-secondary"></i>
                      </router-link>
                      <router-link :to="`/orders/${order.order_id}?edit=true`" title="Edit order">
                        <i class="fas fa-edit text-secondary"></i>
                      </router-link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <pagination
              :total-count="totalItems"
              :current-page="currentPage"
              :on-page-change="pageChange"
              :page-size="20"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Libs
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import to from 'await-to-js';
import router from '@/router';

// Components
import OrderBadge from '@/components/OrderBadge.vue';
import Pagination from '@/components/Pagination.vue';

// Utils
import country from '../../utils/country';
import removeEmptyFields from '@/utils/removeEmptyFields';
import { parseQueryString } from '@/utils/queryString';
import OrderFilters from '@/views/orders/component/OrderFilters.vue';
import WALink from '@/components/WALink.vue';
const toast = useToast();

export default {
  name: 'OrdersList',
  components: { WALink, OrderFilters, OrderBadge, Pagination },
  inject: ['store', 'actions'],
  data() {
    const { query } = useRoute();
    return {
      mediaUrl: process.env.VUE_APP_MEDIA_URL,
      loading: false,
      currentPage: parseInt(query.page) || 1,
      totalItems: 0,
      showFilters: true,
      searchParams: {
        amz_marketplace: query.amz_marketplace || '',
        review_type: query.review_type || '',
        status: query.status || '',
        query: query.query || '',
      },
    };
  },
  watch: {
    showFilters(newVal) {
      const search = removeEmptyFields({ ...this.searchParams });

      if (!newVal && Object.keys(search).length) {
        this.clearSearchFilters();
      }
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData(page = this.currentPage) {
      const search = removeEmptyFields({ ...this.searchParams });
      const queryString = parseQueryString(search);

      this.loading = true;
      const [, data] = await to(this.actions.api('get', `/orders/?page=${page}&${queryString}`));
      this.loading = false;

      if (data?.success) {
        this.store.orders = data.orders;
        this.totalItems = data.totalItems || 0;
      }
    },
    pageChange(page) {
      const search = removeEmptyFields({ ...this.searchParams });
      const queryString = parseQueryString(search);
      this.store.orders = [];
      this.currentPage = page;
      router.push(`/orders/?page=${page}&${queryString}`);
      this.fetchData(page);
    },
    clearSearchFilters() {
      router.push('/orders/?page=1');
      this.currentPage = 1;
      this.searchParams = {
        amz_marketplace: '',
        review_type: '',
        status: '',
        query: '',
      };
      this.fetchData(this.currentPage);
    },
    copyInfo(order) {
      // Making Clipboard
      const clipboard = `Keywords: ${order.product?.keywords} \n\nOrder Number: ${order.amz_order_number} \n\nBuyer PayPal: ${order.buyer_paypal}`;

      // Clipboard Function
      navigator.clipboard.writeText(clipboard).then(
        function () {
          toast.success('Text copied to your clipboard.');
          console.log('Async: Copying to clipboard was successful!');
        },
        function (err) {
          toast.error('Could not copy text.');
          console.error('Async: Could not copy text: ', err);
        },
      );
    },
    country,
  },
};
</script>

<style>
.order-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
</style>
