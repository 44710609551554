<template>
  <div class="card position-sticky top-1 mt-4">
    <ul class="nav flex-column bg-white border-radius-lg p-3">
      <li class="nav-item">
        <a class="nav-link text-body d-flex align-items-center" data-scroll href="#profile">
          <i class="ni ni-spaceship me-2 text-dark opacity-6"></i>
          <span class="text-sm">Profile</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a class="nav-link text-body d-flex align-items-center" data-scroll href="#basic-info">
          <i class="ni ni-books me-2 text-dark opacity-6"></i>
          <span class="text-sm">Basic Info</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a class="nav-link text-body d-flex align-items-center" data-scroll href="#referral-info">
          <i class="ni ni-circle-08 me-2 text-dark opacity-6"></i>
          <span class="text-sm">Referral Info</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a class="nav-link text-body d-flex align-items-center" data-scroll href="#billing-info">
          <i class="ni ni-badge me-2 text-dark opacity-6"></i>
          <span class="text-sm">Billing Info</span>
        </a>
      </li>
      <li v-if="store.role === 'Admin'" class="nav-item pt-2">
        <a class="nav-link text-body d-flex align-items-center" data-scroll href="#password">
          <i class="ni ni-atom me-2 text-dark opacity-6"></i>
          <span class="text-sm">Change Password</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a class="nav-link text-body d-flex align-items-center" data-scroll href="#sessions">
          <i class="ni ni-watch-time me-2 text-dark opacity-6"></i>
          <span class="text-sm">Sessions</span>
        </a>
      </li>
      <li class="nav-item pt-2">
        <a class="nav-link text-body d-flex align-items-center" data-scroll href="#delete">
          <i class="ni ni-settings-gear-65 me-2 text-dark opacity-6"></i>
          <span class="text-sm">Delete Account</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SideNav',
  inject: ['store'],
};
</script>
