import { createApp } from 'vue';
import App from './App.vue';
import { store, actions } from './store';
import router from './router';
import './assets/css/nucleo-icons.css';
import './assets/css/nucleo-svg.css';
import VueTilt from 'vue-tilt.js';
import VueSweetalert2 from 'vue-sweetalert2';
import ArgonDashboard from './argon-dashboard';

// Toast
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

const appInstance = createApp(App);
appInstance.provide('store', store);
appInstance.provide('actions', actions);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(ArgonDashboard);
appInstance.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 3,
  newestOnTop: true,
  position: 'top-center',
});
appInstance.mount('#app');
